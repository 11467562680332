import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AsEnumerable, Enumerable } from 'linq-es2015';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalEventModel } from 'src/app/model/event/global-event-model';
import { GlobalEvent } from 'src/app/model/event/global-events';
import { ExtraStopModel } from 'src/app/model/shipment/extra-stop-model';
import { ShipmentModel } from 'src/app/model/shipment/shipment-model';
import { DeliveryModel } from 'src/app/model/shipment/delivery-model';
import { EventHub } from 'src/app/services/event-hub';
import { ShipmentService } from 'src/app/services/shipment-service';
import { ShipmentStatus } from 'src/app/types/shipment-status';
import { ComponentBase } from '../../component-base';
import { RwDropdownItem } from '../../molecules/rw-dropdown/rw-drowpdown-item';
import { ExtraStopChangeModel } from 'src/app/model/shipment/extra-stop-change-model';

// eslint-disable-next-line no-shadow
enum ShipmentSortDirection {
  PlantAsc,
  PlantDesc,
  ShipmentAsc,
  ShipmentDesc,
  TruckAsc,
  TrucsDesc,
  CheckInAsc,
  CheckInDesc,
  AddressDesc,
  AddressAsc,
  StatusAsc,
  StatusDesc,
  ExecutionAsc,
  ExecutionDesc
}

@Component({
  selector: 'shipments-list',
  templateUrl: './shipments-list.component.html',
  styleUrls: ['./shipments-list.component.scss']
})
export class ShipmentsListComponent extends ComponentBase implements OnInit, OnChanges {
  @Input()
  shipments: Enumerable<ShipmentModel> | null = AsEnumerable([]);

  @Input()
  isLoading: boolean = true;

  @Output()
  refreshShipments: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  editShipmentClick: EventEmitter<ShipmentModel> = new EventEmitter<ShipmentModel>();

  @Output()
  editExtraStopClick: EventEmitter<ExtraStopChangeModel> = new EventEmitter<ExtraStopChangeModel>();

  @Output()
  removeExtraStopClick: EventEmitter<ExtraStopChangeModel> = new EventEmitter<ExtraStopChangeModel>();

  #allFilterText: string = 'ALL';
  #firstSortDirectionFilterItem: RwDropdownItem<ShipmentSortDirection> | null = null;
  #allStatusFilterItem: RwDropdownItem<string> | null = null;
  #statusFilter: RwDropdownItem<string> | null = new RwDropdownItem<string>(this.#allFilterText, this.#allFilterText);
  #sortDirection: RwDropdownItem<ShipmentSortDirection> | null = null;
  #shipmentsCount: number = 0;

  statusFilterItems$: Observable<Enumerable<RwDropdownItem<string>>> = new Observable<Enumerable<RwDropdownItem<string>>>();
  sortTypeItems$: Observable<Enumerable<RwDropdownItem<ShipmentSortDirection>>>
    = new Observable<Enumerable<RwDropdownItem<ShipmentSortDirection>>>();
  sortTypeItems: Enumerable<RwDropdownItem<ShipmentSortDirection>>
    = AsEnumerable<RwDropdownItem<ShipmentSortDirection>>([]);
  filteredShipments: Enumerable<ShipmentModel> = AsEnumerable([]);
  detailsList: Map<string, ShipmentModel> = new Map<string, ShipmentModel>();

  get statusNames() { return this.shipmentService.shipmentStatusNames; }

  get executionStatusNames() { return this.shipmentService.executionStatusNames; }

  get shipmentsCount() { return this.#shipmentsCount; }

  get statusFilter() {
    return this.#statusFilter;
  }
  set statusFilter(value) {
    this.#statusFilter = value;
    this.onFiltersChanges();
  }

  get sortDirection() {
    return this.#sortDirection;
  }
  set sortDirection(value) {
    this.#sortDirection = value;
    this.onFiltersChanges();
  }

  ShipmentStatus = ShipmentStatus;
  ShipmentSortDirection = ShipmentSortDirection;

  constructor(
    private shipmentService: ShipmentService,
    private translationService: TranslateService,
    private eventHub: EventHub) {
    super();

    this.subscribe(this.eventHub.on(GlobalEvent.Shipments_FilterChangeRequest), this.onFilterChangeRequest);
   }

  ngOnInit(): void {
    this.subscribe(this.translationService.onLangChange, this.onLangChange);

    this.filteredShipments = this.shipments ?? AsEnumerable([]);
    this.onFiltersChanges();

    this.getStatusFilterTranslations();
    this.getSortFilterTranslations();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shipments
      && this.shipments) {
      this.onFiltersChanges();
    }
  }

  onSelectedStatusChanged(stat: RwDropdownItem<string>) {
    this.statusFilter = stat;
  }

  onLangChange() {
    this.getStatusFilterTranslations();
    this.getSortFilterTranslations();
  }

  onFilterChangeRequest(event: GlobalEventModel) {
    const translation = this.translationService.instant(
      this.shipmentService.shipmentStatusNames[event.payload as ShipmentStatus ?? ShipmentStatus.Open]);

    this.statusFilter = new RwDropdownItem<string>(translation, event.payload?.toString() ?? ShipmentStatus.Open);
  }

  onFiltersChanges() {
    if (this.statusFilter?.value === this.#allFilterText) {
      this.filteredShipments = this.shipments ?? AsEnumerable([]);
    } else if (this.shipments) {
      this.filteredShipments = this.shipments.Where(s => s.status === this.statusFilter?.value);
    }
    this.#shipmentsCount = this.filteredShipments.Count();

    switch (this.#sortDirection?.value) {
      case ShipmentSortDirection.CheckInAsc:
        this.filteredShipments = this.filteredShipments.OrderBy(x => x.plannedCheckInStart);
        break;
      case ShipmentSortDirection.CheckInDesc:
        this.filteredShipments = this.filteredShipments.OrderBy(
          x => x.plannedCheckInStart,
          (x, y) => (x ?? new Date()) < (y ?? new Date()) ? 1 : ((x ?? new Date()) > (y ?? new Date()) ? -1 : 0));
        break;
      case ShipmentSortDirection.StatusAsc:
        this.filteredShipments = this.filteredShipments.OrderBy(x => x.status, (x, y) => x < y ? 1 : -1 ) ;
        break;
      case ShipmentSortDirection.StatusDesc:
        this.filteredShipments = this.filteredShipments.OrderBy(x => x.status, (x, y) => y < x ? 1 : -1 ) ;
        break;
      case ShipmentSortDirection.AddressAsc:
        this.filteredShipments = this.filteredShipments.OrderBy(x => x.addressShort);
        break;
      case ShipmentSortDirection.AddressDesc:
        this.filteredShipments = this.filteredShipments.OrderBy(x => x.addressShort, (x, y) => x < y ? 1 : (x > y ? -1 : 0));
        break;
      case ShipmentSortDirection.ShipmentAsc:
        this.filteredShipments = this.filteredShipments.OrderBy(x => x.shipmentNumber);
        break;
      case ShipmentSortDirection.ShipmentDesc:
        this.filteredShipments = this.filteredShipments.OrderBy(x => x.shipmentNumber, (x, y) => x < y ? 1 : (x > y ? -1 : 0));
        break;
      case ShipmentSortDirection.TruckAsc:
        this.filteredShipments = this.filteredShipments.OrderBy(x => x.truckId);
        break;
      case ShipmentSortDirection.TrucsDesc:
        this.filteredShipments = this.filteredShipments.OrderBy(x => x.truckId, (x, y) => x < y ? 1 : (x > y ? -1 : 0));
        break;
      case ShipmentSortDirection.PlantAsc:
        this.filteredShipments = this.filteredShipments.OrderBy(x => x.plant);
        break;
      case ShipmentSortDirection.PlantDesc:
        this.filteredShipments = this.filteredShipments.OrderBy(x => x.plant, (x, y) => x < y ? 1 : (x > y ? -1 : 0));
        break;
      case ShipmentSortDirection.ExecutionAsc:
        this.filteredShipments = this.filteredShipments.OrderBy(x => x.executionStatus);
        break;
      case ShipmentSortDirection.ExecutionDesc:
        this.filteredShipments = this.filteredShipments.OrderBy(x => x.executionStatus, (x, y) => x < y ? 1 : (x > y ? -1 : 0));
        break;
    }
  }

  onSwitchFilter(ascOrder: ShipmentSortDirection, descOrder: ShipmentSortDirection) {
    if (this.sortDirection?.value === ascOrder) {
      this.sortDirection = this.sortTypeItems.FirstOrDefault(s => s.value === descOrder);
    } else {
      this.sortDirection = this.sortTypeItems.FirstOrDefault(s => s.value === ascOrder);
    }
  }

  onRefreshClick() {
    this.sortDirection = this.#firstSortDirectionFilterItem;
    this.statusFilter = this.#allStatusFilterItem;

    this.refreshShipments.emit();
  }

  onSipmentDetailsClick(shipment: ShipmentModel | undefined) {
    if (shipment) {
      if (!shipment.isOpen) {
        const observable = this.shipmentService.getShipmentDetails(shipment.shipmentNumber);

        shipment.isDetailsLoading = true;
        for (const item of this.shipments ?? []) {
          item.isOpen = false;
        }

        observable.subscribe({
          next: model => {
            if (model) {
              shipment.isOpen = true;
              //this.detailsList.set(shipment.shipmentNumber, model);
            } else {
              console.log('ERROR retrieving shipment details.');
            }
          },
          error: () => {
            shipment.isDetailsLoading = false;
          },
          complete: () => {
            shipment.isDetailsLoading = false;
          }
        });
      } else {
        shipment.isOpen = false;
      }
    }
  }

  onEditShipment(shipment: ShipmentModel, e: Event) {
    e.preventDefault();
    e.stopPropagation();

    const observable = this.shipmentService.getShipmentDetails(shipment.shipmentNumber);

    shipment.isDetailsLoading = true;
    observable.subscribe({
      next: model => {
        if (model) {
          this.detailsList.set(shipment.shipmentNumber, model);

          this.editShipmentClick.emit(model);
        } else {
          console.log('ERROR retrieving shipment details.');
        }
      },
      error: () => {
        shipment.isDetailsLoading = false;
      },
      complete: () => {
        shipment.isDetailsLoading = false;
      }
    });
  }

  onEditExtraStopClick(extraStop: ExtraStopChangeModel) {
    this.editExtraStopClick.emit(extraStop);
  }

  onRemoveExtraStopClick(extraStop: ExtraStopChangeModel) {
    this.removeExtraStopClick.emit(extraStop);
  }

  private getStatusFilterTranslations() {
    const keyLookup = forkJoin([
      this.translationService.get('PAGES.SHIPMENTS.SHOW_ALL_STATUSES'),
      this.translationService.get(this.shipmentService.shipmentStatusNames[ShipmentStatus.Open]),
      this.translationService.get(this.shipmentService.shipmentStatusNames[ShipmentStatus.InProgress]),
      this.translationService.get(this.shipmentService.shipmentStatusNames[ShipmentStatus.Closed])
    ]);

    const mapOp = ((arr: string[]) => {
      this.#allStatusFilterItem = new RwDropdownItem(arr[0], this.#allFilterText);

      return AsEnumerable([
        this.#allStatusFilterItem,
        new RwDropdownItem(arr[1], ShipmentStatus.Open),
        new RwDropdownItem(arr[2], ShipmentStatus.InProgress),
        new RwDropdownItem(arr[3], ShipmentStatus.Closed)
      ]);
    });

    this.statusFilterItems$ = map(mapOp)(keyLookup);
  }

  private getSortFilterTranslations() {
    const keyLookup = forkJoin([
      this.translationService.get('PAGES.SHIPMENTS.SORT_BY_CHECKIN_ASC'),
      this.translationService.get('PAGES.SHIPMENTS.SORT_BY_CHECKIN_DESC'),
      this.translationService.get('PAGES.SHIPMENTS.SORT_BY_STATUS_ASC'),
      this.translationService.get('PAGES.SHIPMENTS.SORT_BY_STATUS_DESC'),
      this.translationService.get('PAGES.SHIPMENTS.SORT_BY_ADDRESS_ASC'),
      this.translationService.get('PAGES.SHIPMENTS.SORT_BY_ADDRESS_DESC'),
      this.translationService.get('PAGES.SHIPMENTS.SORT_BY_SHIPMENT_ASC'),
      this.translationService.get('PAGES.SHIPMENTS.SORT_BY_SHIPMENT_DESC'),
      this.translationService.get('PAGES.SHIPMENTS.SORT_BY_TRUCK_ASC'),
      this.translationService.get('PAGES.SHIPMENTS.SORT_BY_TRUCK_DESC'),
      this.translationService.get('PAGES.SHIPMENTS.SORT_BY_PLANT_ASC'),
      this.translationService.get('PAGES.SHIPMENTS.SORT_BY_PLANT_DESC'),
      this.translationService.get('PAGES.SHIPMENTS.SORT_BY_EXECUTION_ASC'),
      this.translationService.get('PAGES.SHIPMENTS.SORT_BY_EXECUTION_DESC')
    ]);

    const mapOp = ((arr: string[]) => {
      this.#firstSortDirectionFilterItem = new RwDropdownItem(arr[0], ShipmentSortDirection.CheckInAsc);

      const collection = AsEnumerable([
        this.#firstSortDirectionFilterItem,
        new RwDropdownItem(arr[1], ShipmentSortDirection.CheckInDesc),
        new RwDropdownItem(arr[2], ShipmentSortDirection.StatusAsc),
        new RwDropdownItem(arr[3], ShipmentSortDirection.StatusDesc),
        new RwDropdownItem(arr[4], ShipmentSortDirection.AddressAsc),
        new RwDropdownItem(arr[5], ShipmentSortDirection.AddressDesc),
        new RwDropdownItem(arr[6], ShipmentSortDirection.ShipmentAsc),
        new RwDropdownItem(arr[7], ShipmentSortDirection.ShipmentDesc),
        new RwDropdownItem(arr[8], ShipmentSortDirection.TruckAsc),
        new RwDropdownItem(arr[9], ShipmentSortDirection.TrucsDesc),
        new RwDropdownItem(arr[10], ShipmentSortDirection.PlantAsc),
        new RwDropdownItem(arr[11], ShipmentSortDirection.PlantDesc),
        new RwDropdownItem(arr[12], ShipmentSortDirection.ExecutionAsc),
        new RwDropdownItem(arr[13], ShipmentSortDirection.ExecutionDesc),
      ]);

      this.sortTypeItems = collection;
      return collection;
    });

    this.sortTypeItems$ = map(mapOp)(keyLookup);
  }

};
