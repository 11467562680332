import { ShipmentModel } from './shipment-model';

export class ShipmentChangeModel {
  shipment: ShipmentModel | null = null;
  driverName: string = '';
  licensePlate: string = '';
  driverMobile: string = '';
  messageToDriver: string = '';
  plannedCheckInStart: Date | null = new Date(Date.now());
  checkInTime: string = '';
  dataProvideDuringCheckIn: boolean = false;
  deliveryDates: {[Key: string]: Date | null } = {};
  completionDates: {[Key: string]: Date | null } = {};
}
