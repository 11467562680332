import { Injectable } from '@angular/core';
import { AuthController } from './controllers/auth-controller';
import { CarriersController } from './controllers/carriers-controller';
import { ShipmentsController } from './controllers/shipments-controller';
import { UsersController } from './controllers/users-controller';

@Injectable({
  providedIn: 'root'
})
export class Api {
  constructor(
    public auth: AuthController,
    public shipments: ShipmentsController,
    public users: UsersController,
    public carriers: CarriersController) {
  }
};
