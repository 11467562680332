import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { th } from 'date-fns/locale';
import { CarrierModel } from 'src/app/model/carriers/carrier-model';
import { GlobalEvent } from 'src/app/model/event/global-events';
import { ExtraStopChangeModel } from 'src/app/model/shipment/extra-stop-change-model';
import { ShipmentChangeModel } from 'src/app/model/shipment/shipment-change-model';
import { ShipmentModel } from 'src/app/model/shipment/shipment-model';
import { ShipmentStatModel } from 'src/app/model/shipment/shipment-stat-model';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs-service';
import { CarrierService } from 'src/app/services/carrier-service';
import { EventHub } from 'src/app/services/event-hub';
import { NotificationService } from 'src/app/services/notification-service';
import { PageService } from 'src/app/services/page-service';
import { ShipmentService } from 'src/app/services/shipment-service';
import { UserService } from 'src/app/services/user-service';
import { Pages } from 'src/app/types/pages';
import { PageBase } from '../page-base';

@Component({
  selector: 'app-shipment-page',
  templateUrl: './shipment-page.component.html',
  styleUrls: ['./shipment-page.component.scss']
})
export class ShipmentPageComponent extends PageBase implements OnInit {
  get stats$() { return this.shipmentService.shipmentStats$; }
  get shipments$() { return this.shipmentService.shipments$; }
  get currentUser$() { return this.userService.currentUser$; }
  get carriers$() { return this.carrierService.carriers$; }
  get selectedCarrier$() { return this.carrierService.selectedCarrier$; }
  isShipmentLoading: boolean = true;
  isEditModalVisible: boolean = false;
  isExtraStopEditModalVisible: boolean = false;
  isExtraStopRemoveModalVisible: boolean = false;
  isShipmentUpdating: boolean = false;
  isExtraStopUpdating: boolean = false;
  isExtraStopRemoving: boolean = false;
  editedShipment: ShipmentModel = new ShipmentModel();
  editedExtraStop: ExtraStopChangeModel = new ExtraStopChangeModel();

  constructor(
    breadcrumbService: BreadcrumbsService,
    pageService: PageService,
    private shipmentService: ShipmentService,
    private userService: UserService,
    private carrierService: CarrierService,
    private eventHub: EventHub,
    private notificationService: NotificationService,
    private changeRef: ChangeDetectorRef) {
    super(Pages.Shipments, [Pages.Home], breadcrumbService, pageService);

    this.subscribe(this.carrierService.selectedCarrier$, this.onSelectedCarrierServiceChanged.bind(this));
  }

  ngOnInit(): void {
    this.fetchShipments();
  }

  onLinkClick(model: ShipmentStatModel) {
    this.eventHub.emit(GlobalEvent.Shipments_FilterChangeRequest, model.status);
  }

  onSelectedCarrierServiceChanged(newCarrier: CarrierModel | null) {
    if (newCarrier) {
      this.fetchShipments();
    }
  }

  onSelectedCarrierChanged(newCarrier: CarrierModel) {
    this.carrierService.setSelectedCarrier(newCarrier);
  }

  onRefreshShipments() {
    this.fetchShipments();
  }

  onEditShipmentClick(shipment: ShipmentModel) {
    if (shipment === this.editedShipment) {
      this.editedShipment = new ShipmentModel();
      this.changeRef.detectChanges();
    }

    this.editedShipment = shipment;
    this.isEditModalVisible = true;
  }

  onEditExtraStopClick(extraStop: ExtraStopChangeModel) {
    if (extraStop === this.editedExtraStop) {
      this.editedExtraStop = new ExtraStopChangeModel();
      this.changeRef.detectChanges();
    }
    this.editedExtraStop = extraStop;
    this.isExtraStopEditModalVisible = true;
  }

  onRemoveExtraStopClick(extraStop: ExtraStopChangeModel) {
    if (extraStop === this.editedExtraStop) {
      this.editedExtraStop = new ExtraStopChangeModel();
      this.changeRef.detectChanges();
    }
    this.editedExtraStop = extraStop;
    this.isExtraStopRemoveModalVisible = true;
  }

  onShipmentChangeSave(change: ShipmentChangeModel) {
    this.isShipmentUpdating = true;
    const obs = this.shipmentService.updateShipment(change);

    obs.subscribe({
      next: (result) => {
        if (result) {
          this.isEditModalVisible = false;
          this.notificationService.showSuccessByKey('PAGES.SHIPMENTS.MSG_SHIPMENT_SAVED');
        } else {
          this.notificationService.showErrorByKey('PAGES.SHIPMENTS.MSG_SHIPMENT_NOT_SAVED');
        }
      },
      complete: () => {
        this.isShipmentUpdating = false;
      },
      error: (e) => {
        this.isShipmentUpdating = false;
      }
    });
  }

  onExtraStopChangeSave(change: ExtraStopChangeModel){
    this.isExtraStopUpdating = true;

    const obs = this.shipmentService.updateExtraStop(change.shipmentNumber as string, change);

    obs.subscribe({
      next: (result) => {
        if (result) {
          this.isExtraStopEditModalVisible = false;
          this.notificationService.showSuccessByKey('PAGES.SHIPMENTS.MSG_EXTRA_STOP_SAVED');
        } else {
          this.notificationService.showErrorByKey('PAGES.SHIPMENTS.MSG_EXTRA_STOP_NOT_SAVED');
        }
      },
      complete: () => {
        this.isExtraStopUpdating = false;
        this.onRefreshShipments();
      },
      error: (e) => {
        this.isExtraStopUpdating = false;
      }
    });
  }

  onExtraStopRemove(change: ExtraStopChangeModel){
    this.isExtraStopRemoving = true;
    const obs = this.shipmentService.removeExtraStop(change.shipmentNumber as string, change);

    obs.subscribe({
      next: (result) => {
        if (result) {
          this.isExtraStopRemoveModalVisible = false;
          this.notificationService.showSuccessByKey('PAGES.SHIPMENTS.MSG_EXTRA_STOP_SAVED');
        } else {
          this.notificationService.showErrorByKey('PAGES.SHIPMENTS.MSG_EXTRA_STOP_NOT_SAVED');
        }
      },
      complete: () => {
        this.onRefreshShipments();
        this.isExtraStopRemoving = false;
      },
      error: (e) => {;
        this.isExtraStopRemoving = false;
      }
    });
  }

  private fetchShipments() {
    this.isShipmentLoading = true;
    this
      .shipmentService
      .fetchShipments()
      .subscribe({
        complete: () => {
          this.isShipmentLoading = false;
        },
        error: () => {
          this.isShipmentLoading = false;
        }
      });
  }
}
