import { AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AfterViewInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { format, parse } from 'date-fns';
import { Enumerable } from 'linq-es2015';
import { Observable } from 'rxjs/internal/Observable';
import { pairwise, startWith } from 'rxjs/operators';
import { DeliveryModel } from 'src/app/model/shipment/delivery-model';
import { ExtraStopChangeModel } from 'src/app/model/shipment/extra-stop-change-model';
import { SixfoldLicensePlateModel } from 'src/app/model/sixfold/sixfold-license-plate-model';
import { SixfoldService } from 'src/app/services/sixfold-service';
import { ShipmentStatus } from 'src/app/types/shipment-status';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CountryItem, COUNTRIES } from './country-data';
import { MatSelect } from '@angular/material/select';




@Component({
  selector: 'extra-stop-edit',
  templateUrl: './extra-stop-edit.component.html',
  styleUrls: ['./extra-stop-edit.component.scss']
})
export class ExtraStopEditComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input()
  extraStop: ExtraStopChangeModel  = new ExtraStopChangeModel();

  @Input()
  isUpdating: boolean = false;

  @Output()
  cancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  save: EventEmitter<ExtraStopChangeModel> = new EventEmitter<ExtraStopChangeModel>();

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  countiries: CountryItem[] = COUNTRIES;
  filteredCountiries: ReplaySubject<CountryItem[]> = new ReplaySubject<CountryItem[]>(1);

  warnings: {
    outsideRequestedDate: {[Key: string]: boolean | null };
    anyOutsideRequested: () => boolean;
    anyWarning: () => boolean;
  } = {
    outsideRequestedDate: {},
    anyOutsideRequested() { return Object.keys(this.outsideRequestedDate).length > 0; },
    anyWarning() { return this.anyOutsideRequested(); }
  };

  formGroup: FormGroup = new FormGroup({
    dummy: new FormControl(Validators.required)
  });

  postalCodeControl: FormControl = new FormControl();
  cityControl: FormControl = new FormControl();
  timeAtStopControl: FormControl = new FormControl();

  countryControl: FormControl = new FormControl();
  countryFilterControl: FormControl = new FormControl();

  onDestroySubject  = new Subject();

  constructor() { }

  ngOnInit(): void {
    this.countryControl.setValue(this.countiries[1]);
    this.filteredCountiries.next(this.countiries.slice());

    this.countryFilterControl.valueChanges
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(() => {
        this.filterCountries();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.extraStop) {
      this.setupControls();
    }
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
  }

  clearControl(e: Event, control: FormControl, value: any = '') {
    e.preventDefault();
    e.stopPropagation();

    control.setValue(value);
  }

  onCancel() {
    this.cancel.emit();
  }

  onSave() {
    const changeModel = new ExtraStopChangeModel();
    changeModel.city = this.cityControl.value;
    changeModel.postalCode = this.postalCodeControl.value;
    changeModel.loadingUnloadingMinutes = this.timeAtStopControl.value;
    changeModel.rootSequence = this.extraStop.rootSequence;
    changeModel.sequenceNumber = this.extraStop.sequenceNumber;
    changeModel.shipmentNumber = this.extraStop.shipmentNumber;
    changeModel.name = this.extraStop.name;
    changeModel.countryCode = this.countryControl.value?.id;
    this.save.emit(changeModel);
  }

  protected setInitialValue() {
    this.filteredCountiries
      .pipe(take(1), takeUntil(this.onDestroySubject))
      .subscribe(() => {
          this.singleSelect.compareWith = (a: CountryItem, b: CountryItem) => a && b && a.id === b.id;
      });
  }

  protected filterCountries() {
    if (!this.countiries) {
      return;
    }

    let search = this.countryFilterControl.value;
    if (!search) {
      this.filteredCountiries.next(this.countiries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCountiries.next(
      this.countiries.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private setupControls() {
    this.postalCodeControl.setValue(this.extraStop.postalCode);
    this.cityControl.setValue(this.extraStop.city);
    this.timeAtStopControl.setValue(this.extraStop.loadingUnloadingMinutes);
    this.countryControl.setValue(this.countiries.filter(bank => bank.id === this.extraStop.countryCode));
  }
}
