import { Component, OnInit } from '@angular/core';
import { Enumerable } from 'linq-es2015';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { CarrierModel } from 'src/app/model/carriers/carrier-model';
import { UserModel } from 'src/app/model/users/user';
import { UserChangeModel } from 'src/app/model/users/user-change-model';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs-service';
import { CarrierService } from 'src/app/services/carrier-service';
import { NotificationService } from 'src/app/services/notification-service';
import { CreateUserResult } from 'src/app/services/op-results/create-user-result';
import { UpdateUserResult } from 'src/app/services/op-results/update-user-result';
import { PageService } from 'src/app/services/page-service';
import { UserService } from 'src/app/services/user-service';
import { Pages } from 'src/app/types/pages';
import { PageBase } from '../page-base';

@Component({
  selector: 'user-management-page',
  templateUrl: './user-management-page.component.html',
  styleUrls: ['./user-management-page.component.scss']
})
export class UserManagementPageComponent extends PageBase implements OnInit {
  editedUser: UserModel | null = null;
  editModelVisible: boolean = false;
  currentUser: UserModel | null = null;
  isUpdatingUser: boolean = false;

  get carriers(): Observable<Enumerable<CarrierModel>> {
    return this.carrierService.carriers$;
  }

  constructor(
    breadcrumbService: BreadcrumbsService,
    pageService: PageService,
    private userService: UserService,
    private carrierService: CarrierService,
    private notificationService: NotificationService) {
    super(Pages.UserManagement, [Pages.Home], breadcrumbService, pageService);
  }

  ngOnInit(): void {
    this.subscribe(this.userService.currentUser$, (u) => {
      this.currentUser = u;
    });
  }

  onEditUser(user: UserModel) {
    this.editedUser = user;
    this.editModelVisible = true;
  }

  onSaveUser(changes: UserChangeModel) {
    this.isUpdatingUser = true;

    if (changes.isNew) {
      this.userService.createNewUser(changes)
        .pipe(
          tap(r => {
            if (r === CreateUserResult.DataIssue) {
              this.notificationService.showWarrningByKey('PAGES.USER_MANAGEMENT.MSG_DATA_ISSUE');
            } else if (r === CreateUserResult.Duplicate) {
              this.notificationService.showErrorByKey('PAGES.USER_MANAGEMENT.MSG_USER_EXISTS');
            } else if (r === CreateUserResult.PasswordStrength) {
              this.notificationService.showErrorByKey('PAGES.USER_MANAGEMENT.MSG_USER_PSWD_STRENGTH');
            } else if (r === CreateUserResult.Success) {
              this.notificationService.showSuccessByKey('PAGES.USER_MANAGEMENT.MSG_USER_CREATED');
              this.editModelVisible = false;
            } else {
              this.notificationService.showErrorByKey('PAGES.USER_MANAGEMENT.MSG_UNDEFINED');
            }
          }),
          catchError(e => {
            this.notificationService.showErrorByKey('PAGES.USER_MANAGEMENT.MSG_UNDEFINED');
            return throwError(e);
          }),
          finalize(() => { this.isUpdatingUser = false; })
        )
        .subscribe();
    } else {
      this.userService.updateUser(changes)
        .pipe(
          tap(r => {
            if (r === UpdateUserResult.UserNotFound) {
              this.notificationService.showErrorByKey('PAGES.USER_MANAGEMENT.MSG_USER_NOT_FOUND');
            } else if (r === UpdateUserResult.DataIssue) {
              this.notificationService.showWarrningByKey('PAGES.USER_MANAGEMENT.MSG_DATA_ISSUE');
            } else if (r === UpdateUserResult.Success) {
              this.notificationService.showSuccessByKey('PAGES.USER_MANAGEMENT.MSG_USER_UPDATED');
              this.editModelVisible = false;
            } else {
              this.notificationService.showErrorByKey('PAGES.USER_MANAGEMENT.MSG_UNDEFINED');
            }
          }),
          catchError(e => {
            this.notificationService.showErrorByKey('PAGES.USER_MANAGEMENT.MSG_UNDEFINED');
            return throwError(e);
          }),
          finalize(() => { this.isUpdatingUser = false; })
        )
        .subscribe();
    }
  }

  onCreateNewUser() {
    const newUser = new UserModel();
    newUser.isNew = true;

    this.editedUser = newUser;
    this.editModelVisible = true;
  }
};
