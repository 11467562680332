import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ControllerBase } from '../controller-base';
import { PasswordGrantReponse } from '../reponse/password-grant-reponse';
import { PasswordGrantRequest } from '../request/password-grant-request';
import { RefreshTokenResponse } from '../reponse/refresh-token-response';
import { NotificationService } from 'src/app/services/notification-service';
import { EventHub } from 'src/app/services/event-hub';
import { ResetPasswordResponse } from '../reponse/reset-password-response';
import { LoggingService } from 'src/app/services/logging-service';

@Injectable({
  providedIn: 'root'
})
export class AuthController extends ControllerBase {
  constructor(http: HttpClient, eventHub: EventHub, notificationService: NotificationService, log: LoggingService) {
    super('auth', http, eventHub, notificationService, log);
  }

  token(request: PasswordGrantRequest): Observable<PasswordGrantReponse> {
    return this.post<PasswordGrantReponse>('token', request);
  }

  refresh(): Observable<RefreshTokenResponse> {
    return this.post('refresh', null);
  }

  triggerResetPassword(email: string): Observable<ResetPasswordResponse> {
    return this.post<ResetPasswordResponse>(`resetPassword/${email}/`, null);
  }

  logout(): Observable<boolean> {
    return this.post<boolean>('logout', null);
  }
}
