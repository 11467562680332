import { UtilService } from 'src/app/services/util-service';

export class SixfoldLicensePlateModel {
  licensePlate: string = '';
  carrierId: string = '';

  constructor() {
    this.licensePlate = UtilService.getRandomString(3).toUpperCase() + ' ' + (Math.random() * 10000000).toFixed();
    this.carrierId = UtilService.getRandomString(6).toUpperCase();
  }
};
