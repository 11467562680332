import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rw-input',
  templateUrl: './rw-input.component.html',
  styleUrls: ['./rw-input.component.scss']
})
export class RwInputComponent implements OnInit {
  @Input()
  name: string = 'input' + (Math.random() * 1000).toFixed();

  @Input()
  label: string | null = null;

  @Input()
  type: string = 'text';

  @Input()
  value: string | null = '';

  get placeholderText() {
    if (this.label && this.label.length) {
      return this.label;
    }

    return '';
  }

  constructor() { }

  ngOnInit(): void {
  }

};
