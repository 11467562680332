import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs-service';
import { PageService } from 'src/app/services/page-service';
import { Pages } from 'src/app/types/pages';
import { PageBase } from '../page-base';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent extends PageBase {
  constructor(breadcrumbService: BreadcrumbsService, pageService: PageService) {
    super(Pages.Login, [Pages.Home], breadcrumbService, pageService);
  }
};
