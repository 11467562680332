import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fromIsoToSymbol'
})
export class FromIsoToSymbolPipe implements PipeTransform {
  mapping: { [Key: string]: string } = {
    DAY: 'd',
    '2M': 'cm/s',
    '2X': 'm/min',
    '4G': 'µl',
    '4O': 'µF',
    '4T': 'pF',
    AMP: 'A',
    A87: 'GOhm',
    A93: 'g/m³',
    B34: 'kg/dm³',
    B45: 'kmol',
    B47: 'ND',
    B73: 'MN',
    B75: 'MOhm',
    B78: 'OC',
    B84: 'µA',
    BG: 'Bag',
    BAR: 'bar',
    BIB: 'BIB',
    BO: 'Fl.',
    C10: 'mF',
    C36: 'mol/m³',
    C38: 'Mol/l',
    C39: 'nA',
    '2J': 'cm³/s',
    C41: 'nF',
    C56: 'N/mm²',
    CMQ: 'cm³',
    CDL: 'cd',
    DMQ: 'dm³',
    CMT: 'cm',
    CMK: 'cm²',
    CS: 'Case',
    D10: 'A/V',
    D41: 't/m³',
    D46: 'VAM',
    DD: 'deg',
    DMT: 'dm',
    DR: 'Dr',
    DZN: 'dz',
    EA: 'EA',
    FAR: 'F',
    FAH: '°F',
    FOT: 'Feet',
    FTK: 'ft2',
    GRM: 'g',
    CEL: '°C',
    GL: 'g/l',
    GQ: 'µg/m³',
    GRO: 'gro',
    HUR: 'h',
    HLT: 'hl',
    A97: 'hPa',
    HTZ: 'Hz',
    INH: '"',
    JOU: 'J',
    ANN: 'at',
    KEL: 'K',
    B22: 'kA',
    CA: 'Can',
    CT: 'Car',
    KGM: 'kg',
    28: 'kg/m²',
    KGS: 'kg/s',
    KMQ: 'kg/m³',
    KHZ: 'kHz',
    BX: 'Crate',
    KJO: 'kJ',
    KMT: 'km',
    KMH: 'km/h',
    B49: 'kOhm',
    KPA: 'kPa',
    KVT: 'kV',
    KVA: 'kVA',
    KWT: 'KW',
    KWH: 'kwh',
    LTR: 'l',
    L2: 'l/min',
    LBR: 'lb',
    C62: 'AU',
    MTR: 'm',
    MTS: 'm/s',
    MTK: 'm²',
    S4: 'm²/s',
    MTQ: 'm³',
    MQS: 'm³/s',
    '4K': 'mA',
    MBR: 'mbar',
    MGM: 'mg',
    M1: 'mg/l',
    GP: 'mg/m³',
    MAW: 'VA',
    MHZ: 'MHz',
    SMI: 'mile',
    '4H': 'µm',
    MIN: 'min',
    B98: 'µs',
    C15: 'mJ',
    MLT: 'ml',
    MMT: 'mm',
    MMK: 'mm²',
    D87: 'm_/kg',
    C18: 'mmol',
    MMQ: 'mm³',
    C16: 'mm/s',
    C19: 'mol/kg',
    C34: 'mol',
    MON: 'Months',
    MPA: 'MPa',
    C24: 'mPa.s',
    MQH: 'm³/h',
    C26: 'ms',
    MSK: 'm/s2',
    C29: 'mT',
    '2Z': 'mV',
    MVA: 'PCT',
    C31: 'mW',
    ZW2: 'mW/m*K',
    NEW: 'N',
    C45: 'nm',
    C47: 'ns',
    OHM: 'Ohm',
    PAL: 'Pa',
    PR: 'PAIR',
    PK: 'PAC',
    PF: 'PAL',
    C65: 'Pas',
    59: 'ppm',
    23: 'g/cm³',
    RO: 'ROL',
    SEC: 's',
    SA: 'SAC',
    SPN: 'BKT',
    D33: 'D',
    MIL: 'thou',
    TNE: 't',
    VLT: 'V',
    WTT: 'W',
    ZW1: 'W/m*K',
    WEE: 'Weeks',
    ZTQ: 'EM3',
    NMQ: 'NM3',
    BE: 'BUN',
  };

  transform(value: string | undefined, ...args: unknown[]): unknown {
    const val = value?.toUpperCase();
    if (val && val in this.mapping) {
      return this.mapping[val];
    }
    return value;
  }

}
