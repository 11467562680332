import { Injectable } from '@angular/core';
import { Pages } from '../types/pages';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  #pageNameKeyByType: { [key in Pages]: string } = {
    [Pages.Home] : 'PAGES.HOME.TITLE',
    [Pages.Login] : 'PAGES.LOGIN.TITLE',
    [Pages.Shipments] : 'PAGES.SHIPMENTS.TITLE',
    [Pages.UserManagement] : 'PAGES.USER_MANAGEMENT.TITLE',
    [Pages.NotFound] : 'PAGES.NOT_FOUND.TITLE',
    [Pages.Terms] : 'PAGES.TERMS.TITLE',
  };

  #pageUrlByType: { [key in Pages]: string } = {
    [Pages.Home] : '/',
    [Pages.Login] : '/login',
    [Pages.Shipments] : '/shipments',
    [Pages.UserManagement] : '/users',
    [Pages.NotFound]: '/not-found',
    [Pages.Terms]: '/terms'
  };

  get pageNameKeyByType() { return  this.#pageNameKeyByType; }
  get pageUrlByType() { return this.#pageUrlByType; }
};
