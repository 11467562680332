import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentBase } from '../../component-base';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service';
import { LoginResult } from 'src/app/types/login-result';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent extends ComponentBase implements OnInit {
  form: FormGroup;

  email = new FormControl('', Validators.compose([Validators.email, Validators.required]));
  password = new FormControl('', Validators.compose([Validators.minLength(2), Validators.required]));
  rememberMe = new FormControl(false, Validators.compose([Validators.minLength(2), Validators.required]));
  resetPasswordVisible: boolean = false;
  isLoading: boolean = false;
  resetDone: boolean = false;
  resetSuccess: boolean = false;
  authError: boolean = false;
  serviceError: boolean = false;
  accountLocked: boolean = false;

  hidePassword = true;

  constructor(
    fb: FormBuilder,
    private router: Router,
    private authService: AuthService) {
    super();

    this.form = fb.group({
      email: this.email,
      password: this.password,
      rememberMe: this.rememberMe,
    });
  }

  getErrorMessage(control: FormControl) {
    // TODO: Move to a service
    if (control.hasError('required')) {
      return 'You must enter a value';
    }
    if (control.hasError('minlength')) {
      return 'You must enter a longer value';
    }
    return control.hasError('email') ? 'Not a valid email' : '';
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.isLoading = true;
    this.authError = false;
    this.serviceError = false;
    this.accountLocked = false;

    this.authService
      .logIn(this.email.value, this.password.value, this.rememberMe.value ?? false)
      .subscribe({
        next: (result: LoginResult) => {
          this.resetSuccess = false;
          this.resetDone = true;
          this.isLoading = false;

          if (result === LoginResult.Success) {
            this.router.navigate(['/shipments']);
          } else if (result === LoginResult.Locked) {
            this.accountLocked = true;
          } else {
            this.authError = true;
          }
        },
        error: (e) => {
          this.resetSuccess = false;
          this.resetDone = true;
          this.isLoading = false;
          this.serviceError = true;
        }
      });
  }

  onGoBack() {
    this.resetDone = false;
    this.resetPasswordVisible = false;
    this.authError = false;
    this.serviceError = false;
    this.accountLocked = false;
  }

  onResetPassword() {
    this.isLoading = true;
    this.authError = false;
    this.serviceError = false;
    this.accountLocked = false;

    this.authService.triggerResetPassword(this.email.value).subscribe({
      next: (result: boolean) => {
        this.isLoading = false;
        this.resetSuccess = result;
        this.resetDone = true;
      }
    });
  }
}
