import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Enumerable } from 'linq-es2015';
import { CarrierModel } from 'src/app/model/carriers/carrier-model';
import { UserModel } from 'src/app/model/users/user';
import { UserChangeModel } from 'src/app/model/users/user-change-model';
import { AuthService } from 'src/app/services/auth-service';

@Component({
  selector: 'user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit, OnChanges {
  @Input()
  user: UserModel | null = null;

  @Input()
  carriers: Enumerable<CarrierModel> | null = null;

  @Input()
  isUpdating: boolean = false;

  @Output()
  save: EventEmitter<UserChangeModel> = new EventEmitter<UserChangeModel>();

  @Output()
  cancel: EventEmitter<void> = new EventEmitter<void>();

  passwordResetEnabled: boolean = true;
  emailControl: FormControl = new FormControl(null, [Validators.required]);
  carrierControl: FormControl = new FormControl('');
  passwordControl: FormControl = new FormControl(null, Validators.compose([this.validatePassword.bind(this)]));
  passwordRepeatControl: FormControl = new FormControl(null, Validators.compose([this.validatePasswordRepeat.bind(this)]));
  isAdminControl: FormControl = new FormControl();
  isActiveControl: FormControl = new FormControl();
  formGroup: FormGroup = new FormGroup({
    email: this.emailControl,
    carrier: this.carrierControl,
    password: this.passwordControl,
    passwordRepeat: this.passwordRepeatControl,
    isAdmin: this.isAdminControl,
    isActive: this.isActiveControl
  }, Validators.compose([this.validateCarriers.bind(this), this.validatePasswordMatches.bind(this)]));

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user) {
      this.emailControl.setValue(this.user?.email);
      this.carrierControl.setValue(this.user?.carriers);
      this.passwordControl.setValue('');
      this.passwordRepeatControl.setValue('');
      this.isAdminControl.setValue(this.user?.isAdmin);
      this.isActiveControl.setValue(this.user?.isActive);
      this.passwordResetEnabled = true;
    }

    if (changes.user || changes.user == null) {
      this.formGroup.markAsPristine();
    }
  }

  clearControl(e: Event, control: FormControl) {
    e.preventDefault();
    e.stopPropagation();

    control.setValue('');
  }

  onCancel() {
    this.passwordResetEnabled = true;

    this.cancel.emit();
  }

  onSave() {
    const saveModel = new UserChangeModel();
    saveModel.email = this.emailControl.value;
    saveModel.password = this.passwordControl.value;
    saveModel.isActive = this.isActiveControl.value;
    saveModel.isAdmin = this.isAdminControl.value;
    if (saveModel.isAdmin) {
      saveModel.carriers = [];
    } else {
      saveModel.carriers = this.carrierControl.value;
    }
    saveModel.isNew = this.user?.isNew ?? true;

    this.passwordResetEnabled = true;
    this.save.emit(saveModel);
  }

  onResetPassword() {
    this.isUpdating = true;

    this.authService.triggerResetPassword(this.user?.email ?? '').subscribe({
      next: () => {
        this.isUpdating = false;
        this.passwordResetEnabled = false;
      },
      error: (e) => {
        this.isUpdating = false;
      }
    });
  }

  validatePassword(validatePassword: AbstractControl): ValidationErrors | null {
    if (!validatePassword.value) {
      if (!this.user?.isNew) {
        return null;
      } else {
        return { nopassword: true };
      }
    }

    return null;
  }

  validatePasswordRepeat(repeatPassword: AbstractControl): ValidationErrors | null {
    if (this.passwordControl.value) {
      if (this.passwordControl.value !== repeatPassword.value) {
        return { nomatch: true };
      }
    }

    return null;
  }

  validatePasswordMatches(control: AbstractControl): {[key: string]: boolean} | null {
    const password = control.get('password');
    const repeat = control.get('passwordRepeat');
    if (!password) {
      return null;
    }
    return password.value === repeat?.value ? null : { nomatch: true };
  };

  validateCarriers(control: AbstractControl): {[key: string]: boolean} | null {
    const carriers = control.get('carrier');
    const isAdmin = control.get('isAdmin');
    if (isAdmin && isAdmin.value) {
      return null;
    }

    if (!carriers || !carriers.value || !carriers.value.length) {
      return { noCarriers: true };
    }

    return null;
  };
}
