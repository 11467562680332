import { Injectable, ErrorHandler } from '@angular/core';
import { LoggingService } from './services/logging-service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(private loggingService: LoggingService) {
    super();
  }

  handleError(error: Error) {
    this.loggingService.logException(error);
    console.error(error);
  }
}
