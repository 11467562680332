import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError, concatMap } from 'rxjs/operators';
import { GlobalEvent } from './model/event/global-events';
import { UserModel } from './model/users/user';
import { AuthService } from './services/auth-service';
import { EventHub } from './services/event-hub';
import { SessionStorageService } from './services/session-storage-service';
import { UserService } from './services/user-service';
import { LoginResult } from './types/login-result';
import { SessionStoreItem } from './types/session-store-item';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router,
    private sessionStore: SessionStorageService,
    private authService: AuthService,
    private eventHub: EventHub) {

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.currentUser$.pipe(
      concatMap((user: UserModel | null) => {
        if (this.sessionStore.hasKnownItem(SessionStoreItem.IdToken)
          && this.sessionStore.hasKnownItem(SessionStoreItem.UserId)) {
            const userId = this.sessionStore.getKnowItem(SessionStoreItem.UserId);
            if (!user && userId) {
              return this.refreshUser(userId, next);
            } else {
              return of(true);
            }
        } else {
          return this.authService.refreshToken().pipe(
            concatMap(r => {
              if (r === LoginResult.Success) {
                const userId = this.sessionStore.getKnowItem(SessionStoreItem.UserId) ?? '-1';
                return this.refreshUser(userId, next);
              } else {
                this.navigateToLogin(next);

                return of(false);
              }
            })
          );
        }
      }),
      catchError((error) => {
        this.navigateToLogin(next);
        return of(false);
      })
    );
  }

  navigateToLogin(next: ActivatedRouteSnapshot) {
    this.router.navigate(['/login'], {
      queryParams: {
        returnRoute: next.url
      }
     });
  }

  refreshUser(userId: string, next: ActivatedRouteSnapshot) {
    return this.
      userService.
      fetchUser(userId).
      pipe(
        map(result => {
          if (result) {
            this.eventHub.emit(GlobalEvent.Login);
            return true;
          }

          this.navigateToLogin(next);
          return false;
        })
      );
  }
};
