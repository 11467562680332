import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {

  transform(value: number | undefined, ...args: unknown[]): unknown {
    if (!value) {
      return value;
    }

    return Math.floor(value);
  }

}
