import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rw-error-underline',
  templateUrl: './rw-error-underline.component.html',
  styleUrls: ['./rw-error-underline.component.scss']
})
export class RwErrorUnderlineComponent implements OnInit {

  @Input()
  isSuffix: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
