import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AsEnumerable, Enumerable } from 'linq-es2015';
import { CarrierModel } from 'src/app/model/carriers/carrier-model';
import { ShipmentStatModel } from 'src/app/model/shipment/shipment-stat-model';
import { UserModel } from 'src/app/model/users/user';
import { CarrierService } from 'src/app/services/carrier-service';
import { ShipmentService } from 'src/app/services/shipment-service';
import { ComponentBase } from '../../component-base';
import { RwDropdownItem } from '../../molecules/rw-dropdown/rw-drowpdown-item';

@Component({
  selector: 'shipments-info',
  templateUrl: './shipments-info.component.html',
  styleUrls: ['./shipments-info.component.scss']
})
export class ShipmentsInfoComponent extends ComponentBase implements OnInit {
  @Input()
  shipmentStats: Enumerable<ShipmentStatModel> | null = null;
  @Input()
  currentUser: UserModel | null = null;

  @Output()
  shipmentTypeClicked: EventEmitter<ShipmentStatModel> = new EventEmitter<ShipmentStatModel>();

  @Output()
  selectedCarrierChange: EventEmitter<CarrierModel> = new EventEmitter<CarrierModel>();

  #carriers: Enumerable<CarrierModel> | null = null;
  #selectedCarrier: CarrierModel | null = null;
  #selectedCarrierItem: RwDropdownItem<CarrierModel> | null = null;

  carrierItems: Enumerable<RwDropdownItem<CarrierModel>> | null = AsEnumerable([]);

  get statusNames() { return this.shipmentService.shipmentStatusNames; }

  @Input()
  get carriers(): Enumerable<CarrierModel> | null {
    return this.#carriers;
  }
  set carriers(value: Enumerable<CarrierModel> | null) {
    this.#carriers = value;
    this.carrierItems = this.#carriers?.Select(c => new RwDropdownItem(c.name, c)) ?? AsEnumerable([]);
  }

  @Input()
  get selectedCarrier(): CarrierModel | null {
    return this.#selectedCarrier;
  }
  set selectedCarrier(value: CarrierModel | null) {
    this.#selectedCarrier = value;
    if (value != null) {
      this.#selectedCarrierItem = new RwDropdownItem(value.name, value);
    }
  }

  @Input()
  get selectedCarrierItem(): RwDropdownItem<CarrierModel> | null {
    return this.#selectedCarrierItem;
  }
  set selectedCarrierItem(value: RwDropdownItem<CarrierModel> | null) {
    this.#selectedCarrierItem = value;
    this.selectedCarrierChange.emit(value?.value);
  }

  get carrierItemsCount(): number {
    return (this.carrierItems?.Count() ?? 0);
  }

  get areCarriersLoading() {
    return this.carrierService.isLoading;
  }

  constructor(
    private shipmentService: ShipmentService,
    private carrierService: CarrierService) {
    super();
   }

  ngOnInit(): void {
  }

  onStatusLinkClick(e: Event, model: ShipmentStatModel) {
    e.preventDefault();
    e.stopPropagation();

    this.shipmentTypeClicked.emit(model);
  }
}
