import { Component, ContentChild, Input, AfterContentChecked, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { AsEnumerable, Enumerable } from 'linq-es2015';
import { GlobalEvent } from 'src/app/model/event/global-events';
import { EventHub } from 'src/app/services/event-hub';
import { ComponentBase } from '../../component-base';
import { RwDropdownItem } from './rw-drowpdown-item';

@Component({
  selector: 'rw-dropdown',
  templateUrl: './rw-dropdown.component.html',
  styleUrls: ['./rw-dropdown.component.scss']
})
export class RwDropdownComponent<TPayload> extends ComponentBase implements AfterContentChecked {
  @Input()
  items: Enumerable<RwDropdownItem<TPayload>> | null = AsEnumerable<RwDropdownItem<TPayload>>([]);

  @Input()
  selected: RwDropdownItem<TPayload> | null = null;

  @Output()
  selectedChange: EventEmitter<RwDropdownItem<TPayload>> = new EventEmitter<RwDropdownItem<TPayload>>();

  @Input()
  label: string = '';

  @Input()
  noUnderscore: boolean = false;

  @Input()
  templateScope: any = {};

  @ViewChild('defaultItemTemplate', { static: true })
  defaultItemTemplate: TemplateRef<RwDropdownItem<TPayload>> | null = null;

  @ViewChild('defaultHeaderTemplate', { static: true })
  defaultHeaderTemplate: TemplateRef<RwDropdownItem<TPayload>> | null = null;

  @ContentChild('itemTemplate')
  itemTemplate: TemplateRef<RwDropdownItem<TPayload>> | null = null;

  @ContentChild('headerTemplate')
  headerTemplate: TemplateRef<RwDropdownItem<TPayload>> | null = null;

  isPopupVisible: boolean = false;

  get labelText() {
    if (!this.label) {
      return '';
    } else {
      return `${this.label}: `;
    }
  }

  get itemsCount() {
    return this.items?.Count() ?? 0;
  }

  AsRwDropdownItem = RwDropdownItem.AsRwDropdownItem;

  constructor(private eventHub: EventHub) {
    super();

    this.subscribe(this.eventHub.on(GlobalEvent.BodyClick), this.onBodyClick.bind(this));
  }

  ngAfterContentChecked(): void {
    if (!this.itemTemplate) {
      this.itemTemplate = this.defaultItemTemplate;
    }

    if (!this.headerTemplate) {
      this.headerTemplate = this.defaultHeaderTemplate;
    }

    if ((!this.selected || (this.items?.Any(i => i.name === this.selected?.name && i.value === this.selected?.value )) === false)
      && (this.items?.Count())) {
      this.selected = this.items?.First();
    }
  }

  onCurrentClick(e: Event): void {
    e.stopPropagation();
    e.preventDefault();

    this.isPopupVisible = true;
  }

  onItemClick(item: RwDropdownItem<TPayload>): void {
    this.selected = item;
    this.selectedChange.emit(this.selected);
  }

  onBodyClick() {
    this.isPopupVisible = false;
  }
};
