import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AsEnumerable, Enumerable } from 'linq-es2015';
import { combineLatest, forkJoin, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CarrierModel } from 'src/app/model/carriers/carrier-model';
import { UserModel } from 'src/app/model/users/user';
import { CarrierService } from 'src/app/services/carrier-service';
import { UserService } from 'src/app/services/user-service';
import { ComponentBase } from '../../component-base';
import { RwDropdownItem } from '../../molecules/rw-dropdown/rw-drowpdown-item';

enum UsersSortDirection {
  UsernameAsc,
  UsernameDesc,
  CarrierIdAsc,
  CarrierIdDesc,
  CarrierNameAsc,
  CarrierNameDesc,
  StatusAsc,
  StatusDesc,
  AdminAsc,
  AdminDesc,
  CreateDateAsc,
  CreateDateDesc
}

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent extends ComponentBase implements OnInit {
  @Output()
  edit: EventEmitter<UserModel> = new EventEmitter<UserModel>();

  #sortDirection: RwDropdownItem<UsersSortDirection>
   = new RwDropdownItem<UsersSortDirection>(UsersSortDirection.CreateDateAsc.toString(), UsersSortDirection.CreateDateAsc);
  #firstSortDirectionFilterItem: RwDropdownItem<UsersSortDirection> | null = null;
  #users: Enumerable<UserModel> = AsEnumerable([]);
  #carriers: Enumerable<CarrierModel> = AsEnumerable([]);

  sortTypeItems$: Observable<Enumerable<RwDropdownItem<UsersSortDirection>>>
    = new Observable<Enumerable<RwDropdownItem<UsersSortDirection>>>();
  sortTypeItems: Enumerable<RwDropdownItem<UsersSortDirection>>
    = AsEnumerable<RwDropdownItem<UsersSortDirection>>([]);
  filteredUsers: Enumerable<UserModel> = AsEnumerable([]);
  isLoading: boolean = true;
  usersCount: number = 0;
  allCarriersWord: string = 'All';

  get sortDirection() {
    return this.#sortDirection;
  }
  set sortDirection(value) {
    this.#sortDirection = value;
    this.onFiltersChanges();
  }

  UsersSortDirection = UsersSortDirection;

  constructor(
    private translationService: TranslateService,
    private userService: UserService,
    private carrierService: CarrierService) {
    super();
   }

  ngOnInit(): void {
    this.subscribe(this.translationService.onLangChange, this.onLangChange);
    this.subscribe(combineLatest(
      [this.userService.users$, this.carrierService.carriers$]
    ), this.onDataChanged);

    this.getSortFilterTranslations();
    this.refreshData();
  }

  refreshData() {
    this.isLoading = true;

    this.userService.fetchAllUsers().subscribe();
  }

  onDataChanged(data: [Enumerable<UserModel>, Enumerable<CarrierModel>]) {
    this.#users = data[0];
    this.#carriers = data[1];
    this.usersCount = data[0].Count();

    this.onFiltersChanges();

    this.isLoading = false;
  }

  onFiltersChanges() {
    this.filteredUsers = this.#users;

    if (this.sortDirection.value === UsersSortDirection.UsernameAsc) {
      this.filteredUsers = this.filteredUsers.OrderBy(u => u.email);
    } else if (this.sortDirection.value === UsersSortDirection.UsernameDesc) {
      this.filteredUsers = this.filteredUsers.OrderBy(u => u.email, (a, b) => b > a ? 1 : -1);
    } else if (this.sortDirection.value === UsersSortDirection.StatusAsc) {
      this.filteredUsers = this.filteredUsers.OrderBy(u => u.isActive);
    } else if (this.sortDirection.value === UsersSortDirection.StatusDesc) {
      this.filteredUsers = this.filteredUsers.OrderBy(u => u.isActive, (a, b) => b > a ? 1 : -1);
    } else if (this.sortDirection.value === UsersSortDirection.AdminAsc) {
      this.filteredUsers = this.filteredUsers.OrderBy(u => u.isAdmin);
    } else if (this.sortDirection.value === UsersSortDirection.AdminDesc) {
      this.filteredUsers = this.filteredUsers.OrderBy(u => u.isAdmin, (a, b) => b > a ? 1 : -1);
    } else if (this.sortDirection.value === UsersSortDirection.CarrierIdAsc) {
      this.filteredUsers = this.filteredUsers.OrderBy(u => u.carriers.length);
    } else if (this.sortDirection.value === UsersSortDirection.CarrierIdDesc) {
      this.filteredUsers = this.filteredUsers.OrderBy(u => u.carriers.length, (a, b) => b > a ? 1 : -1);
    } else if (this.sortDirection.value === UsersSortDirection.CarrierNameAsc) {
      this.filteredUsers = this.filteredUsers.OrderBy(u => u.carriers.length);
    } else if (this.sortDirection.value === UsersSortDirection.CarrierNameDesc) {
      this.filteredUsers = this.filteredUsers.OrderBy(u => u.carriers.length, (a, b) => b > a ? 1 : -1);
    } else if (this.sortDirection.value === UsersSortDirection.CreateDateAsc) {
      this.filteredUsers = this.filteredUsers.OrderBy(u => u.created);
    } else if (this.sortDirection.value === UsersSortDirection.CreateDateDesc) {
      this.filteredUsers = this.filteredUsers.OrderBy(u => u.created, (a, b) => b > a ? 1 : -1);
    }
  }

  onLangChange() {
    this.getSortFilterTranslations();
  }

  onEditUser(user: UserModel, e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.edit.emit(user);
  }

  onSwitchFilter(ascOrder: UsersSortDirection, descOrder: UsersSortDirection) {
    if (this.sortDirection.value === ascOrder) {
      this.sortDirection = this.sortTypeItems.FirstOrDefault(s => s.value === descOrder);
    } else {
      this.sortDirection = this.sortTypeItems.FirstOrDefault(s => s.value === ascOrder);
    }
  }

  getCarrierIds(user: UserModel) {
    if (user.isAdmin) {
      return this.allCarriersWord;
    }

    if (!user
      || !user.carriers.length) {
      return '-';
    }

    const str = user.carriers.join(', ');

    if (str.length > 25) {
      return str.substr(0, Math.min(str.length, 25)) + '...';
    } else {
      return str;
    }
  }

  getCarrierNames(user: UserModel) {
    if (user.isAdmin) {
      return this.allCarriersWord;
    }

    if (!user
      || !user.carriers.length) {
      return '-';
    }

    if (user.carriers.length === this.#carriers.Count()) {
      return this.allCarriersWord;
    }

    const str = this.#carriers
      .Where(uc => user.carriers.filter(c => c === uc.carrierId).length > 0)
      .Select(c => c.name)
      .ToArray().join(', ');

    if (!str) {
      return '-';
    }

    if (str.length > 35) {
      return str.substr(0, Math.min(str.length, 35)) + '...';
    } else {
      return str;
    }
  }

  private getSortFilterTranslations() {
    const keyLookup = forkJoin([
      this.translationService.get('PAGES.USER_MANAGEMENT.SORT_BY_CREATED_ASC'),
      this.translationService.get('PAGES.USER_MANAGEMENT.SORT_BY_CREATED_DESC'),
      this.translationService.get('PAGES.USER_MANAGEMENT.SORT_BY_USERNAME_ASC'),
      this.translationService.get('PAGES.USER_MANAGEMENT.SORT_BY_USERNAME_DESC'),
      this.translationService.get('PAGES.USER_MANAGEMENT.SORT_BY_CARRIERID_ASC'),
      this.translationService.get('PAGES.USER_MANAGEMENT.SORT_BY_CARRIERID_DESC'),
      this.translationService.get('PAGES.USER_MANAGEMENT.SORT_BY_CARRIERNAME_ASC'),
      this.translationService.get('PAGES.USER_MANAGEMENT.SORT_BY_CARRIERNAME_DESC'),
      this.translationService.get('PAGES.USER_MANAGEMENT.SORT_BY_STATUS_ASC'),
      this.translationService.get('PAGES.USER_MANAGEMENT.SORT_BY_STATUS_DESC'),
      this.translationService.get('PAGES.USER_MANAGEMENT.SORT_BY_ADMIN_ASC'),
      this.translationService.get('PAGES.USER_MANAGEMENT.SORT_BY_ADMIN_DESC'),
      this.translationService.get('PAGES.USER_MANAGEMENT.LIST.ALL_CARRIERS_WORD'),
    ]);

    const mapOp = ((arr: string[]) => {
      this.#firstSortDirectionFilterItem = new RwDropdownItem(arr[0], UsersSortDirection.CreateDateAsc);

      this.sortTypeItems = AsEnumerable([
        this.#firstSortDirectionFilterItem,
        new RwDropdownItem(arr[1], UsersSortDirection.CreateDateDesc),
        new RwDropdownItem(arr[2], UsersSortDirection.UsernameAsc),
        new RwDropdownItem(arr[3], UsersSortDirection.UsernameDesc),
        new RwDropdownItem(arr[4], UsersSortDirection.CarrierIdAsc),
        new RwDropdownItem(arr[5], UsersSortDirection.CarrierIdDesc),
        new RwDropdownItem(arr[6], UsersSortDirection.CarrierNameAsc),
        new RwDropdownItem(arr[7], UsersSortDirection.CarrierNameDesc),
        new RwDropdownItem(arr[8], UsersSortDirection.StatusAsc),
        new RwDropdownItem(arr[9], UsersSortDirection.StatusDesc),
        new RwDropdownItem(arr[10], UsersSortDirection.AdminAsc),
        new RwDropdownItem(arr[11], UsersSortDirection.AdminDesc),
      ]);

      this.allCarriersWord = arr[12];
    });

    let sub: Subscription | null = null;
    sub = map(mapOp)(keyLookup).subscribe({
      complete: () => {
        sub?.unsubscribe();
      }
    });
  }

}
