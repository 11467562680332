import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CookieService } from 'ngx-cookie-service';

import { DateFnsDateAdapter } from './infrastructure/custom-date-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';

import { RootComponent } from './components/root/root.component';
import { TopNavComponent } from './components/organisms/top-nav/top-nav.component';
import { FooterComponent } from './components/organisms/footer/footer.component';
import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { ShipmentPageComponent } from './components/pages/shipment-page/shipment-page.component';
import { UserManagementPageComponent } from './components/pages/user-management-page/user-management-page.component';
import { NotFoundPageComponent } from './components/pages/not-found-page/not-found-page.component';
import { BreadcrumbsComponent } from './components/organisms/breadcrumbs/breadcrumbs.component';
import { ShipmentsInfoComponent } from './components/organisms/shipments-info/shipments-info.component';
import { ShipmentsListComponent } from './components/organisms/shipments-list/shipments-list.component';
import { RwDropdownComponent } from './components/molecules/rw-dropdown/rw-dropdown.component';
import { LoaderComponent } from './components/molecules/loader/loader.component';
import { RwButtonComponent } from './components/molecules/rw-button/rw-button.component';
import { ShipmentListDetailsComponent } from './components/organisms/shipment-list-details/shipment-list-details.component';
import { NewLinePipe } from './pipes/new-line.pipe';
import { RwModalComponent } from './components/molecules/rw-modal/rw-modal.component';
import { ShipmentEditComponent } from './components/organisms/shipment-edit/shipment-edit.component';
import { ExtraStopEditComponent } from './components/organisms/extra-stop-edit/extra-stop-edit.component';
import { ExtraStopRemoveComponent } from './components/organisms/extra-stop-remove/extra-stop-remove.component';
import { RwInputComponent } from './components/molecules/rw-input/rw-input.component';
import { MaxLengthPipe } from './pipes/max-length.pipe';
import { LoginFormComponent } from './components/organisms/login-form/login-form.component';
import { RwErrorUnderlineComponent } from './components/molecules/rw-error-underline/rw-error-underline.component';
import { RwPopupComponent } from './components/molecules/rw-popup/rw-popup.component';
import { ApiVersionInterceptor } from './api/interceptors/api-version-interceptor';
import { AuthenticateInterceptor } from './api/interceptors/authenticate-interceptor';
import { AuthGuard } from './auth-guard';
import { NotificationSnackBarComponent } from './components/molecules/notification-snack-bar/notification-snack-bar.component';
import { UserListComponent } from './components/organisms/user-list/user-list.component';
import { UserEditComponent } from './components/organisms/user-edit/user-edit.component';
import { ErrorHandlerService } from './error-handler';
import { LoggingService } from './services/logging-service';
import { TermsPageComponent } from './components/pages/terms-page/terms-page.component';
import { RoundPipe } from './pipes/round.pipe';
import { FromIsoToSymbolPipe } from './pipes/from-iso-to-symbol.pipe';
import { NoCommaPipe } from './pipes/no-comma.pipe';
import { LanguageSelectComponent } from './components/organisms/language-select/language-select.component';
import { PasteTrapDirective } from './directives/paste-trap.directive';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

// AoT requires an exported function for factories
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ApiVersionInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthenticateInterceptor, multi: true },
];

export const DateFormat = {
  parse: {
    dateInput: 'input',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    RootComponent,
    TopNavComponent,
    FooterComponent,
    LoginPageComponent,
    ShipmentPageComponent,
    UserManagementPageComponent,
    NotFoundPageComponent,
    BreadcrumbsComponent,
    ShipmentsInfoComponent,
    ShipmentsListComponent,
    RwDropdownComponent,
    LoaderComponent,
    RwButtonComponent,
    ShipmentListDetailsComponent,
    NewLinePipe,
    RwModalComponent,
    ShipmentEditComponent,
    ExtraStopEditComponent,
    ExtraStopRemoveComponent,
    RwInputComponent,
    MaxLengthPipe,
    LoginFormComponent,
    RwErrorUnderlineComponent,
    RwPopupComponent,
    NotificationSnackBarComponent,
    UserListComponent,
    UserEditComponent,
    TermsPageComponent,
    RoundPipe,
    FromIsoToSymbolPipe,
    NoCommaPipe,
    LanguageSelectComponent,
    PasteTrapDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatSelectModule,
    MatTabsModule,
    NgxMatSelectSearchModule,
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'empty',
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AuthGuard,
    CookieService,
    LoggingService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    //{ provide: DateAdapter, useClass: DateFnsDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: DateFormat },
    httpInterceptorProviders ],
  bootstrap: [RootComponent],
})
export class AppModule {}
