export class ExtraStopChangeModel {
    rootSequence: number = -1;
    sequenceNumber: number = -1;
    city: string = '';
    postalCode: string = '';
    countryCode: string = '';
    name: string = '';
    loadingUnloadingMinutes: string = '';
    shipmentNumber: string = '';
}
