import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { ShipmentPageComponent } from './components/pages/shipment-page/shipment-page.component';
import { UserManagementPageComponent } from './components/pages/user-management-page/user-management-page.component';
import { TermsPageComponent } from './components/pages/terms-page/terms-page.component';
import { NotFoundPageComponent } from './components/pages/not-found-page/not-found-page.component';
import { AuthGuard } from './auth-guard';

const routes: Routes = [
  { path: '', redirectTo: 'shipments', pathMatch: 'full'},
  { path: 'login', component: LoginPageComponent },
  { path: 'shipments', component: ShipmentPageComponent, canActivate: [ AuthGuard ] },
  { path: 'users', component: UserManagementPageComponent, canActivate: [ AuthGuard ] },
  { path: 'terms', component: TermsPageComponent },
  { path: '**', component: NotFoundPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
