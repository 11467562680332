import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs-service';
import { PageService } from 'src/app/services/page-service';
import { Pages } from 'src/app/types/pages';
import { PageBase } from '../page-base';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent extends PageBase implements OnInit {

  constructor(breadcrumbService: BreadcrumbsService, pageService: PageService) {
    super(Pages.NotFound, [Pages.Home], breadcrumbService, pageService);
  }

  ngOnInit(): void {
  }

};
