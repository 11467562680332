import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ExtraStopChangeModel } from 'src/app/model/shipment/extra-stop-change-model';
import { Subject } from 'rxjs';

@Component({
  selector: 'extra-stop-remove',
  templateUrl: './extra-stop-remove.component.html',
  styleUrls: ['./extra-stop-remove.component.scss']
})
export class ExtraStopRemoveComponent {
  @Input()
  extraStop: ExtraStopChangeModel  = new ExtraStopChangeModel();

  @Input()
  isUpdating: boolean = false;

  @Output()
  cancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  remove: EventEmitter<ExtraStopChangeModel> = new EventEmitter<ExtraStopChangeModel>();

  formGroup: FormGroup = new FormGroup({
    dummy: new FormControl(Validators.required)
  });

  postalCodeControl: FormControl = new FormControl();
  cityControl: FormControl = new FormControl();
  timeAtStopControl: FormControl = new FormControl();

  countryControl: FormControl = new FormControl();
  countryFilterControl: FormControl = new FormControl();

  onDestroySubject  = new Subject();

  constructor() { }

  clearControl(e: Event, control: FormControl, value: any = '') {
    e.preventDefault();
    e.stopPropagation();

    control.setValue(value);
  }

  onCancel() {
    this.cancel.emit();
  }

  onRemove() {
    this.remove.emit(this.extraStop);
  }
}
