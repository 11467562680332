import { Injectable } from '@angular/core';
import { AsEnumerable, Enumerable } from 'linq-es2015';
import { Observable, ReplaySubject, throwError, timer } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Api } from '../api/api';
import { CarrierModel } from '../model/carriers/carrier-model';
import { GlobalEvent } from '../model/event/global-events';
import { EventHub } from './event-hub';
import { NotificationService } from './notification-service';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {
  isLoading: boolean = false;

  #carriers$: ReplaySubject<Enumerable<CarrierModel>> = new ReplaySubject<Enumerable<CarrierModel>>(1);
  #selectedCarrier$: ReplaySubject<CarrierModel | null> = new ReplaySubject<CarrierModel | null>(1);

  private selectedCarrier: CarrierModel | null = null;
  private carrierItems: Enumerable<CarrierModel> = AsEnumerable([]);

  get selectedCarrier$(): Observable<CarrierModel | null> {
    return this.#selectedCarrier$;
  }

  get carriers$(): Observable<Enumerable<CarrierModel>> {
    return this.#carriers$;
  }

  constructor(
    private eventHub: EventHub,
    private api: Api,
    private notificationService: NotificationService) {
    this.eventHub.on(GlobalEvent.Logout).subscribe({
      next: this.resetData.bind(this)
    });

    this.#selectedCarrier$.next(this.selectedCarrier);
    this.#carriers$.next(this.carrierItems);
  }

  fetchCarriers(): Observable<boolean> {
    this.isLoading = true;

    return this.api.carriers.getAllCarriers().pipe(
      map((r) => {
        this.isLoading = false;

        if (r.Any()) {
          this.selectedCarrier = r.First();;
          this.carrierItems = r;

          this.#selectedCarrier$.next(this.selectedCarrier);
          this.#carriers$.next(this.carrierItems);

          return true;
        } else {
          this.notificationService.showErrorByKey('ERRORS.NO_CARRIER_ASSIGNED');

          return false;
        }
      }),
      catchError((e) => {
        this.isLoading = false;

        return throwError(e);
      })
    );
  }

  setSelectedCarrier(newCarrier: CarrierModel) {
    if (this.selectedCarrier?.carrierId !== newCarrier?.carrierId) {
      const carrier = this.carrierItems.FirstOrDefault(c => c.carrierId === newCarrier.carrierId);
      if (carrier != null) {
        this.selectedCarrier = carrier;
        this.#selectedCarrier$.next(this.selectedCarrier);
      }
    }
  }

  private resetData() {
    this.selectedCarrier = null;
    this.carrierItems = AsEnumerable([]);

    this.#selectedCarrier$.next(this.selectedCarrier);
    this.#carriers$.next(this.carrierItems);
  }

  private addDummyData() {
    const carrier = new CarrierModel();
    carrier.name = 'Wool Enterprises Ltd.';
    carrier.carrierId = '0200001192';

    const carrier2 = new CarrierModel();
    carrier2.name = 'Rockwool Ltd.';
    carrier2.carrierId = '41328000';

    this.carrierItems = AsEnumerable([carrier, carrier2]);
    this.selectedCarrier = carrier;

    this.#selectedCarrier$.next(this.selectedCarrier);
    this.#carriers$.next(this.carrierItems);
  }
};
