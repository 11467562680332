import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs-service';
import { PageService } from 'src/app/services/page-service';
import { Pages } from 'src/app/types/pages';
import { PageBase } from '../page-base';

@Component({
  selector: 'app-terms-page',
  templateUrl: './terms-page.component.html',
  styleUrls: ['./terms-page.component.scss']
})
export class TermsPageComponent extends PageBase implements OnInit {

  constructor(
    protected breadcrumbService: BreadcrumbsService,
    protected pageService: PageService) {
    super(Pages.Terms, [Pages.Home], breadcrumbService, pageService);
  }

  ngOnInit(): void {
  }

}
