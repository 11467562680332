import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsEnumerable, Enumerable } from 'linq-es2015';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CarrierModel } from 'src/app/model/carriers/carrier-model';
import { EventHub } from 'src/app/services/event-hub';
import { LoggingService } from 'src/app/services/logging-service';
import { NotificationService } from 'src/app/services/notification-service';
import { ControllerBase } from '../controller-base';
import { CarriersResponse } from '../reponse/carriers-response';
import { LicensePlatesResponse } from '../reponse/license-plates.response';

@Injectable({
  providedIn: 'root'
})
export class CarriersController extends ControllerBase {
  constructor(http: HttpClient, eventHub: EventHub, notificationService: NotificationService, log: LoggingService) {
    super('carriers', http, eventHub, notificationService, log);
  }

  getAllCarriers(): Observable<Enumerable<CarrierModel>> {
    return this.get<CarriersResponse>('').pipe(
      map((r) => {
        if (r.isSuccess) {
          const carrierModels = r.carriers.map(c => {
            const model = new CarrierModel();
            model.name = c.name;
            model.carrierId = c.carrierNumber;
            return model;
          });

          return AsEnumerable(carrierModels);
        }

        return AsEnumerable([]);
      }),
      catchError((e) => this.handleError(e))
    );
  }

  getLicensePlates(carrierId: string): Observable<string[]> {
    return this.get<LicensePlatesResponse>(`licenseplates/${carrierId}`).pipe(
      map((r) => {
        if (r.isSuccess) {
          return r.licensePlates;
        } else {
          return [];
        }
      })
    );
  }
};
