import { Component, EventEmitter, Input, Output, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeliveryModel } from 'src/app/model/shipment/delivery-model';
import { ExtraStopModel } from 'src/app/model/shipment/extra-stop-model';
import { ShipmentModel } from 'src/app/model/shipment/shipment-model';
import { EventHub } from 'src/app/services/event-hub';
import { ShipmentService } from 'src/app/services/shipment-service';
import { ComponentBase } from '../../component-base';
import { GlobalEvent } from 'src/app/model/event/global-events';
import { ThrowStmt } from '@angular/compiler';
import { ExtraStopChangeModel } from 'src/app/model/shipment/extra-stop-change-model';

@Component({
  selector: 'shipment-list-details',
  templateUrl: './shipment-list-details.component.html',
  styleUrls: ['./shipment-list-details.component.scss']
})
export class ShipmentListDetailsComponent extends ComponentBase  implements OnInit, OnChanges {
  @Input()
  shipmentDetails: ShipmentModel | undefined = undefined;

  @Output()
  editExtraStopClick: EventEmitter<ExtraStopChangeModel> = new EventEmitter<ExtraStopChangeModel>();

  @Output()
  removeExtraStopClick: EventEmitter<ExtraStopChangeModel> = new EventEmitter<ExtraStopChangeModel>();

  get deliveryCount() {
    return this.shipmentDetails?.deliveries?.length ?? 0;
  }

  get stopsCount() {
    return this.shipmentDetails?.stops?.length ?? 0;
  }

  constructor(
    private shipmentService: ShipmentService,
    private translationService: TranslateService,
    private eventHub: EventHub) {
    super();

   }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
  }

  onExtraStopAdd(stop: ExtraStopModel, e: Event) {
    e.preventDefault();
    e.stopPropagation();
    const extraStopChangeModel = new ExtraStopChangeModel();
    extraStopChangeModel.rootSequence = stop.rootSequence;
    extraStopChangeModel.name = stop.name;
    extraStopChangeModel.shipmentNumber = this.shipmentDetails?.shipmentNumber ?? '';
    this.editExtraStopClick.emit(extraStopChangeModel);
  }

  onRemoveStopDelete(stop: ExtraStopModel, e: Event) {
    e.preventDefault();
    e.stopPropagation();
    const extraStopChangeModel = new ExtraStopChangeModel();
    extraStopChangeModel.rootSequence = stop.rootSequence;
    extraStopChangeModel.sequenceNumber = stop.sequenceNumber ?? 0;
    extraStopChangeModel.shipmentNumber = this.shipmentDetails?.shipmentNumber ?? '';
    this.removeExtraStopClick.emit(extraStopChangeModel);
  }
}
