import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsEnumerable, Enumerable } from 'linq-es2015';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DeliveryModel } from 'src/app/model/shipment/delivery-model';
import { ExtraStopModel } from 'src/app/model/shipment/extra-stop-model';
import { ShipmentModel } from 'src/app/model/shipment/shipment-model';
import { EventHub } from 'src/app/services/event-hub';
import { LoggingService } from 'src/app/services/logging-service';
import { NotificationService } from 'src/app/services/notification-service';
import { ExecutionStatus } from 'src/app/types/execution-status';
import { ShipmentStatus } from 'src/app/types/shipment-status';
import { ControllerBase } from '../controller-base';
import { ExtraStopChangeApiModel } from '../model/extra-stop-change-api-model';
import { ShipmentApiModel } from '../model/shipment-api-model';
import { ShipmentChangeApiModel } from '../model/shipment-change-api-model';
import { ShipmentUpdateResponse } from '../reponse/shipment-update-response';
import { ShipmentsResponse } from '../reponse/shipments-response';

@Injectable({
  providedIn: 'root'
})
export class ShipmentsController extends ControllerBase {
  constructor(http: HttpClient, eventHub: EventHub, notificationService: NotificationService, log: LoggingService) {
    super('shipments', http, eventHub, notificationService, log);
  }

  getShipments(carrierId: string): Observable<Enumerable<ShipmentModel>> {
    return this.get<ShipmentsResponse>(carrierId).pipe(
      map(r => {
        if (r.isSuccess) {
          const shipments = r.shipments.map((s) => this.toShipmentModel(s));
          return AsEnumerable<ShipmentModel>(shipments);
        }

        return AsEnumerable<ShipmentModel>([]);
      }),
      catchError(e => this.handleError(e))
    );
  }

  updateShipment(shipmentNumber: string, changes: ShipmentChangeApiModel): Observable<ShipmentStatus | undefined> {
    return this.put<ShipmentUpdateResponse>(`${shipmentNumber}`, changes).pipe(
      map(r => r.status),
      catchError((e) => {
        if (e.status === 400) {
          return of(undefined);
        }
        return this.handleError(e);
      })
    );
  }

  updateExtraStop(shipmentNumber: string, changes: ExtraStopChangeApiModel): Observable<ExtraStopChangeApiModel | undefined> {
    return this.put<ExtraStopChangeApiModel>(`${shipmentNumber}/stops`, changes).pipe(
      map(r => r),
      catchError((e) => {
        if (e.status === 400) {
          return of(undefined);
        }
        return this.handleError(e);
      })
    );
  }

  removeExtraStop(shipmentNumber: string, changes: ExtraStopChangeApiModel): Observable<ExtraStopChangeApiModel | undefined> {
    return this.post<ExtraStopChangeApiModel>(`${shipmentNumber}/stops/delete`, changes).pipe(
      map(r => r),
      catchError((e) => {
        if (e.status === 400) {
          return of(undefined);
        }
        return this.handleError(e);
      })
    );
  }

  private toShipmentModel(api: ShipmentApiModel): ShipmentModel {
    const model = new ShipmentModel();

    model.address = api.address;
    model.addressShort = api.addressShort;
    model.checkInDate = api.checkInDate ? new Date(api.checkInDate) : null;
    model.checkInTime = api.checkInTime;
    model.deliveries = api.deliveries?.map(d => {
      const delivery = new DeliveryModel();

      delivery.completionDate = d.completionDate ? new Date(d.completionDate) : null;
      delivery.deliveryNumber = d.deliveryNumber;
      delivery.eta = d.eta ? new Date(d.eta) : null;
      delivery.requestedDeliveryDateFrom = d.requestedDeliveryDateFrom ? new Date(d.requestedDeliveryDateFrom) : null;
      delivery.requestedDeliveryDateTo = d.requestedDeliveryDateTo ? new Date(d.requestedDeliveryDateTo) : null;
      delivery.salesOrder = d.salesOrder;
      delivery.shipTo = d.shipTo;
      delivery.volume = d.volume;
      delivery.volumeUnit = d.volumeUnit;
      delivery.weight = d.weight;
      delivery.weightUnit = d.weightUnit;

      return delivery;
    }) ?? null;
    model.distance = api.distance;
    model.distanceUnit = api.distanceUnit;
    model.driverMobile = api.driverMobile;
    model.driverName = api.driverName;
    model.licensePlate = api.licensePlate;
    model.loadingMeter = api.loadingMeter;
    model.messageToCarrier = api.messageToCarrier;
    model.messageToDriver = api.messageToDriver;
    model.plannedCheckInEnd = api.plannedCheckInEnd ? new Date(api.plannedCheckInEnd) : null;
    model.plannedCheckInStart = api.plannedCheckInStart ? new Date(api.plannedCheckInStart) : null;
    //model.plannedCheckInStart = new Date(Date.now() + (3 * 3600 * 1000));
    model.shipmentNumber = api.shipmentNumber;
    model.shippingConditions = api.shippingConditions;
    model.specialProcess = api.specialProcess;
    model.status = ShipmentStatus[api.status];
    model.executionStatus = ExecutionStatus[api.executionStatus];
    model.truckId = api.truckId;
    model.volume = api.volume;
    model.volumeUnit = api.volumeUnit;
    model.weight = api.weight;
    model.weightUnit = api.weightUnit;
    model.plant = api.transportPlanningPoint;
    model.shipmentCostCurrency = api.shipmentCostCurrency;
    model.shipmentCost = api.shipmentCost;
    model.dataProvideDuringCheckIn = api.dataProvideDuringCheckIn;
    model.extraStopsCount = api.extraStopsCount;
    model.stopsCount = api.stopsCount;

    model.stops = api.stops ?.map(d => {
      const stop = new ExtraStopModel();
      stop.rootSequence = d.rootSequence;
      stop.sequenceNumber = d.sequenceNumber;
      stop.deliveryNumbers = d.deliveryNumbers;
      stop.name = d.name;
      stop.postalCode = d.postalCode;
      stop.city = d.city;
      stop.countryCode = d.countryCode;
      stop.loadingUnloadingMinutes = d.loadingUnloadingMinutes;
      stop.type = d.type;

      return stop;
    }) ?? null;

    return model;
  }
};
