import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newLine'
})
export class NewLinePipe implements PipeTransform {

  transform(value: string | undefined | null): string | null | undefined{
    if (value
      && value.length) {
      return value.replace(/\r\n/g, '<br />').replace(/\n/g, '<br />');
    }

    return value;
  }
}
