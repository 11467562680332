import { Component, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CarrierModel } from '../model/carriers/carrier-model';
import { RwDropdownItem } from './molecules/rw-dropdown/rw-drowpdown-item';

@Component({
  template: ''
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ComponentBase implements OnDestroy {
  protected subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    if ((Window as any).debug) {
      console.log('Destroy ' + this.constructor.name);
    }
  }

  protected subscribe<T>(observable: Observable<T>, nextCallback: (v: T) => void ): Subscription {
    const sub = observable.subscribe(nextCallback.bind(this));
    this.subscriptions.add(sub);
    return sub;
  }
};
