import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Api } from '../api/api';
import { GlobalEvent } from '../model/event/global-events';
import { LoginResult } from '../types/login-result';
import { SessionStoreItem } from '../types/session-store-item';
import { EventHub } from './event-hub';
import { SessionStorageService } from './session-storage-service';
import { UserService } from './user-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private api: Api,
    private router: Router,
    private sessionStore: SessionStorageService,
    private userService: UserService,
    private eventHub: EventHub) {
    this.eventHub.on(GlobalEvent.LogoutRequest).subscribe({
      next: this.onLogoutRequested.bind(this)
    });
  }

  logIn(username: string, password: string, rememberMe: boolean): Observable<LoginResult> {
    return this.api.auth.token({
      username,
      password,
      rememberMe
    }).pipe(
      mergeMap(response => {
        if (response.isSuccess
          && response.token
          && response.userId) {

            this.sessionStore.setKnownItem(SessionStoreItem.IdToken, response.token.idToken);
            this.sessionStore.setKnownItem(SessionStoreItem.UserId, response.userId);
            return this.userService.fetchUser(response.userId).pipe(
              map(userResult => {
                if (userResult) {
                  this.eventHub.emit(GlobalEvent.Login);
                  return LoginResult.Success;
                } else {
                  return LoginResult.Failure;
                }
              })
            );
        }

        return of(LoginResult.Failure);
      }),
      catchError((e) => {
        if (e.status === 403) {
          if (e.error.type === 'AccountLocked') {
            return of(LoginResult.Locked);
          }
          return of(LoginResult.Failure);
        } else {
          return throwError(e);
        }
      })
    );
  }

  refreshToken(): Observable<LoginResult> {

    return this.api.auth.refresh().pipe(
      mergeMap(response => {
        if (response.isSuccess
          && response.token
          && response.userId) {

            this.sessionStore.setKnownItem(SessionStoreItem.IdToken, response.token.idToken);
            this.sessionStore.setKnownItem(SessionStoreItem.UserId, response.userId);
            return this.userService.fetchUser(response.userId).pipe(
              map(userResult => {
                if (userResult) {
                  this.eventHub.emit(GlobalEvent.Login);
                  return LoginResult.Success;
                } else {
                  return LoginResult.Failure;
                }
              })
            );
        }

        return of(LoginResult.Failure);
      }),
      catchError((e) => {
        if (e.status === 403) {
          if (e.error.type === 'AccountLocked') {
            return of(LoginResult.Locked);
          }
          return of(LoginResult.Failure);
        } else if (e.status === 401) {
          return of(LoginResult.Failure);
        } else {
          return throwError(e);
        }
      })
    );
  }

  triggerResetPassword(email: string): Observable<boolean> {
    return this.api.auth.triggerResetPassword(email).pipe(
      map(response => {
        if (response.isSuccess) {
          return true;
        }

        return false;
      })
    );
  }

  logOut(): Observable<boolean> {
    return this.api.auth.logout().pipe(
      map(response => {
        if (response) {
          this.onLogoutRequested();
          return true;
        }
        return false;
      })
    );
  }

  onLogoutRequested() {
    this.sessionStore.removeKnownItem(SessionStoreItem.IdToken);
    this.sessionStore.removeKnownItem(SessionStoreItem.UserId);

    this.eventHub.emit(GlobalEvent.Logout);

    this.router.navigateByUrl('/login');
  }
};
