import { Injectable } from '@angular/core';
import { AsEnumerable, Enumerable } from 'linq-es2015';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Api } from '../api/api';
import { CarrierModel } from '../model/carriers/carrier-model';
import { SixfoldLicensePlateModel } from '../model/sixfold/sixfold-license-plate-model';
import { CarrierService } from './carrier-service';

@Injectable({
  providedIn: 'root'
})
export class SixfoldService {
  #licensePlateSubject$: ReplaySubject<Enumerable<SixfoldLicensePlateModel>> = new ReplaySubject<Enumerable<SixfoldLicensePlateModel>>(1);

  get licensePlates$(): Observable<Enumerable<SixfoldLicensePlateModel>> {
    return this.#licensePlateSubject$;
  }

  constructor(
    private carrierService: CarrierService,
    private api: Api) {
    this.carrierService.selectedCarrier$.subscribe(this.onCarrierSelected.bind(this));
  }

  private onCarrierSelected(carrier: CarrierModel | null) {
    if (carrier) {
      this.api.carriers.getLicensePlates(carrier.carrierId).subscribe({
        next: (r) => {
          const result = r.map((res) => {
            const model = new SixfoldLicensePlateModel();
            model.carrierId = carrier.carrierId;
            model.licensePlate = res;
            return model;
          });

          this.#licensePlateSubject$.next(AsEnumerable(result));
        }
      });
    }
  }
};
