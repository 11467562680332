import { Injectable } from '@angular/core';
import { AsEnumerable, asEnumerable, Enumerable } from 'linq-es2015';
import { Observable, of, ReplaySubject, Subject, Subscription, timer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Api } from '../api/api';
import { ShipmentChangeApiModel } from '../api/model/shipment-change-api-model';
import { ExtraStopChangeApiModel } from '../api/model/extra-stop-change-api-model';
import { GlobalEvent } from '../model/event/global-events';
import { DeliveryModel } from '../model/shipment/delivery-model';
import { ShipmentChangeModel } from '../model/shipment/shipment-change-model';
import { ShipmentModel } from '../model/shipment/shipment-model';
import { ExtraStopChangeModel } from '../model/shipment/extra-stop-change-model';
import { ExtraStopModel } from '../model/shipment/extra-stop-model';
import { ShipmentStatModel } from '../model/shipment/shipment-stat-model';
import { ExecutionStatus } from '../types/execution-status';
import { ShipmentStatus } from '../types/shipment-status';
import { CarrierService } from './carrier-service';
import { EventHub } from './event-hub';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService {
  #statsArray: ShipmentStatModel[] = [];
  #shipments: ShipmentModel[] = [];
  #extraStops: ExtraStopModel[] = [];
  #shipmentStats$: ReplaySubject<Enumerable<ShipmentStatModel>> = new ReplaySubject<Enumerable<ShipmentStatModel>>(1);
  #shipments$: ReplaySubject<Enumerable<ShipmentModel>> = new ReplaySubject<Enumerable<ShipmentModel>>(1);
  #extraStops$: ReplaySubject<Enumerable<ExtraStopModel>> = new ReplaySubject<Enumerable<ExtraStopModel>>(1);

  shipmentStatusNames: { [key in ShipmentStatus]: string } = {
    [ShipmentStatus.Open]: 'STATUSES.OPEN',
    [ShipmentStatus.InProgress]: 'STATUSES.INPROGRESS',
    [ShipmentStatus.Closed]: 'STATUSES.CLOSED'
  };

  executionStatusNames: { [key in ExecutionStatus]: string } = {
    [ExecutionStatus.Undefined]: 'undefined',
    [ExecutionStatus.NotCheckIn]: 'EXECUTION.NOTCHECKIN',
    [ExecutionStatus.CheckIn]: 'EXECUTION.CHECKIN',
    [ExecutionStatus.DeliveryStarted]: 'EXECUTION.DELIVERYSTARTED'
  };

  get shipments$(): Observable<Enumerable<ShipmentModel>> {
    return this.#shipments$;
  }

  get extraStops$(): Observable<Enumerable<ExtraStopModel>> {
    return this.#extraStops$;
  }

  get shipmentStats$(): Observable<Enumerable<ShipmentStatModel>> {
    return this.#shipmentStats$;
  }

  constructor(
    private eventHub: EventHub,
    private carrierService: CarrierService,
    private api: Api) {
    this.resetData();

    this.eventHub.on(GlobalEvent.Logout).subscribe({
      next: this.resetData.bind(this)
    });
  }

  fetchShipments(): Observable<void> {
    this.resetData();
    const s = new Subject<void>();

    let sub: Subscription | null = null;
    sub = this.carrierService.selectedCarrier$.subscribe({
      next: (c) => {
        if (c && c.carrierId) {
          this.api.shipments.getShipments(c.carrierId).subscribe({
            next: (res) => {
              this.processShipments(res);

              sub?.unsubscribe();
              s.next();
              s.complete();
            }
          });
        } else {
          sub?.unsubscribe();
          s.next();
          s.complete();
        }
      }
    });

    return s;
  }

  getShipmentDetails(shipmentNumber: string): Observable<ShipmentModel | null> {
    const s = new Subject<ShipmentModel | null>();

    setTimeout(() => {
      //const data = this.getDummyShipmentData(shipmentNumber);

      const shipment = this.#shipments.filter((sh) => sh.shipmentNumber === shipmentNumber);
      if (shipment && shipment.length) {
        s.next(shipment[0]);
      } else {
        s.next(null);
      }
      s.complete();
    }, 100);

    return s;
  }

  getExtraStop(shipmentNumber: string, rootSequence: number, sequenceNumber: number): Observable<ExtraStopModel | null> {
    const s = new Subject<ExtraStopModel | null>();

    setTimeout(() => {
      //const data = this.getDummyShipmentData(shipmentNumber);

      const extraStop = this.#extraStops.filter((es) => es.rootSequence === rootSequence
        && es.sequenceNumber === sequenceNumber);

      if (extraStop && extraStop.length) {
        //s.next(extraStop[0]);
        s.next(new ExtraStopModel());
      } else {
        s.next(null);
      }
      s.complete();
    }, 100);

    return s;
  }

  updateShipment(changes: ShipmentChangeModel): Observable<boolean> {
    const filtered = this.#shipments.filter(s => s.shipmentNumber === changes.shipment?.shipmentNumber);
    if (filtered && filtered.length) {
      const shipment = filtered[0];

      const apiChanges = new ShipmentChangeApiModel();
      apiChanges.driverMobile = changes.driverMobile;
      apiChanges.driverMobileChanged = changes.driverMobile !== shipment.driverMobile;
      apiChanges.driverName = changes.driverName;
      apiChanges.driverNameChnage = changes.driverName !== shipment.driverName;
      apiChanges.licensePlate = changes.licensePlate;
      apiChanges.licensePlateChanged = changes.licensePlate !== shipment.licensePlate;
      apiChanges.messageToDriver = changes.messageToDriver;
      apiChanges.messageToDriverChanged = changes.messageToDriver !== shipment.messageToDriver;
      apiChanges.plannedCheckInStart = changes.plannedCheckInStart;
      apiChanges.plannedCheckinStartChanged = changes.plannedCheckInStart?.getTime() !== shipment.plannedCheckInStart?.getTime();
      apiChanges.dataProvideDuringCheckIn = changes.dataProvideDuringCheckIn;
      apiChanges.dataProvideDuringCheckInChanged = changes.dataProvideDuringCheckIn !== shipment.dataProvideDuringCheckIn;

      const deliveryChanges = Object.keys(changes.deliveryDates);
      apiChanges.Deliveries = deliveryChanges.map((num) => {
        const delivery = shipment.deliveries?.filter(d => d.deliveryNumber === num)[0];

        const deliveryChange = {
          deliveryNumber: num,
          etaChanged: changes.deliveryDates[num]?.getTime() !== delivery?.eta?.getTime(),
          eta: changes.deliveryDates[num],
          completionDateChanged: false,
          completionDate: null as Date | null
        };
        if (num in changes.completionDates) {
          deliveryChange.completionDate = changes.completionDates[num];
          deliveryChange.completionDateChanged = delivery?.completionDate !== changes.completionDates[num];
        }

        return deliveryChange;
      });

      return this.api.shipments.updateShipment(shipment.shipmentNumber, apiChanges).pipe(
        map((result) => {
          if (result) {
            shipment.driverMobile = changes.driverMobile;
            shipment.driverName = changes.driverName;
            shipment.plannedCheckInStart = changes.plannedCheckInStart;
            shipment.messageToDriver = changes.messageToDriver;
            shipment.licensePlate = changes.licensePlate;
            shipment.status = result;
            shipment.dataProvideDuringCheckIn = changes.dataProvideDuringCheckIn;

            shipment.deliveries?.forEach(delivery => {
              if (delivery.deliveryNumber in changes.deliveryDates) {
                const deliveryDate = changes.deliveryDates[delivery.deliveryNumber];

                delivery.eta = deliveryDate;
                delivery.completionDate = changes.completionDates[delivery.deliveryNumber];
              }
            });

            this.#shipments$.next(AsEnumerable(this.#shipments));

            return true;
          }

          return false;
        })
      );
    } else {
      return of(false);
    }
  }

  updateExtraStop(shipmentNumber: string, changes: ExtraStopChangeModel, ): Observable<ExtraStopChangeApiModel> {
    const apiChanges = new ExtraStopChangeApiModel();
    apiChanges.rootSequence = changes.rootSequence;
    apiChanges.sequenceNumber = changes.sequenceNumber;
    apiChanges.city = changes.city;
    apiChanges.postalCode = changes.postalCode;
    apiChanges.countryCode = changes.countryCode;
    apiChanges.loadingUnloadingMinutes = changes.loadingUnloadingMinutes.toString();

    return this.api.shipments.updateExtraStop(shipmentNumber, apiChanges).pipe(
      map((result) => result ?? new ExtraStopChangeApiModel())
    );
  }

  removeExtraStop(shipmentNumber: string, changes: ExtraStopChangeModel, ): Observable<ExtraStopChangeApiModel> {
    const apiChanges = new ExtraStopChangeApiModel();
    apiChanges.rootSequence = changes.rootSequence;
    apiChanges.sequenceNumber = changes.sequenceNumber;

    return this.api.shipments.removeExtraStop(shipmentNumber, apiChanges).pipe(
      map((result) => result ?? new ExtraStopChangeApiModel())
    );
  }

  private processShipments(shipments: Enumerable<ShipmentModel>) {
    this.#shipments = shipments.ToArray();

    this.#statsArray = [];
    let stat = new ShipmentStatModel();
    stat.count = shipments.Count(s => s.status === ShipmentStatus.Open);
    stat.status = ShipmentStatus.Open;
    this.#statsArray.push(stat);

    stat = new ShipmentStatModel();
    stat.count = shipments.Count(s => s.status === ShipmentStatus.InProgress);
    stat.status = ShipmentStatus.InProgress;
    this.#statsArray.push(stat);

    stat = new ShipmentStatModel();
    stat.count = shipments.Count(s => s.status === ShipmentStatus.Closed);
    stat.status = ShipmentStatus.Closed;
    this.#statsArray.push(stat);

    this.#shipmentStats$.next(asEnumerable(this.#statsArray));
    this.#shipments$.next(shipments);
  }

  private resetData() {
    this.#shipments$.next(asEnumerable([]));
    this.resetStats();
  }

  private resetStats() {
    this.#statsArray = [];
    let stat = new ShipmentStatModel();
    stat.count = 0;
    stat.status = ShipmentStatus.Open;
    this.#statsArray.push(stat);

    stat = new ShipmentStatModel();
    stat.count = 0;
    stat.status = ShipmentStatus.InProgress;
    this.#statsArray.push(stat);

    stat = new ShipmentStatModel();
    stat.count = 0;
    stat.status = ShipmentStatus.Closed;
    this.#statsArray.push(stat);
    this.#shipmentStats$.next(asEnumerable(this.#statsArray));
  }

  private addDummyData() {
    this.#statsArray = [];
    this.#shipments = [];

    const count = (Math.random() * 1000) % 45 + 3;

    for (let i = 0; i < count; i++) {
      this.#shipments.push(new ShipmentModel());
    }

    const shipmentsArray = asEnumerable(this.#shipments);

    let stat = new ShipmentStatModel();
    stat.count = shipmentsArray.Count(s => s.status === ShipmentStatus.Open);
    stat.status = ShipmentStatus.Open;
    this.#statsArray.push(stat);

    stat = new ShipmentStatModel();
    stat.count = shipmentsArray.Count(s => s.status === ShipmentStatus.InProgress);
    stat.status = ShipmentStatus.InProgress;
    this.#statsArray.push(stat);

    stat = new ShipmentStatModel();
    stat.count = shipmentsArray.Count(s => s.status === ShipmentStatus.Closed);
    stat.status = ShipmentStatus.Closed;
    this.#statsArray.push(stat);

    this.#shipmentStats$.next(asEnumerable(this.#statsArray));
    this.#shipments$.next(shipmentsArray);
  }

  private getDummyShipmentData(shipmentNumber: string): ShipmentModel | null {
    const filtered = this.#shipments.filter(s => s.shipmentNumber === shipmentNumber);
    if (filtered
      && filtered.length === 1) {
      const shipment = filtered[0];

      if (!shipment.deliveries || !shipment.deliveries.length) {
        const delCount = Math.random() * 4 + 1;
        for (let i = 0; i < delCount; i++) {
          const delivery = new DeliveryModel();
          if (shipment.status === ShipmentStatus.Closed) {
            delivery.eta = new Date((shipment.plannedCheckInStart?.getTime() ?? Date.now()) + (Math.random() * 100000000));
          }
          shipment.deliveries?.push(delivery);
        }
      }

      return shipment;
    }

    return null;
  }
};
