import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { NEVER, Observable, throwError } from 'rxjs';
import { GlobalEvent } from '../model/event/global-events';
import { EventHub } from '../services/event-hub';
import { LoggingService } from '../services/logging-service';
import { NotificationService } from '../services/notification-service';

export abstract class ControllerBase {
  constructor(
    protected name: string,
    private http: HttpClient,
    private eventHub: EventHub,
    private notificationService: NotificationService,
    private log: LoggingService) {
  }

  protected post<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    return this.http.post<T>(`api/${this.name}/${url}`, body, options);
  }

  protected get<T>(url: string, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}): Observable<T> {
    return this.http.get<T>(`api/${this.name}/${url}`, options);
  }

  protected put<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    return this.http.put<T>(`api/${this.name}/${url}`, body, options);
  }

  protected handleError(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        this.notificationService.showWarrningByKey('ERRORS.FORCED_LOGOUT');
        this.eventHub.emit(GlobalEvent.LogoutRequest);

        return NEVER;
      } else if (error.status === 403) {
        this.notificationService.showWarrningByKey('ERRORS.UNAUTHORISED_RESPONSE');

        return NEVER;
      } else if (error.status === 500) {
        this.notificationService.showErrorByKey('ERRORS.SYSTEM_ERROR', 5000, error.message);

        return throwError(error);
      } else {
        return throwError(error);
      }
    } else {
      return throwError(error);
    }
  }
};
