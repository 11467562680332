import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalEvent } from 'src/app/model/event/global-events';
import { AppCookieService } from 'src/app/services/app-cookie-service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs-service';
import { CarrierService } from 'src/app/services/carrier-service';
import { NotificationService } from 'src/app/services/notification-service';
import { Pages } from 'src/app/types/pages';
import { EventHub } from '../../services/event-hub';
import { ComponentBase } from '../component-base';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent extends ComponentBase implements OnInit {
  constructor(
    translate: TranslateService,
    private eventHub: EventHub,
    private bcService: BreadcrumbsService,
    private carrierService: CarrierService,
    private cookieService: AppCookieService) {
    super();
    const lang = this.cookieService.getLang();
    if (lang) {
      translate.use(lang);
    } else {
      translate.use('en');
    }

    this.subscribe(this.eventHub.on(GlobalEvent.Login), (e) => {
      this.carrierService.fetchCarriers().subscribe();
    });
  }

  ngOnInit() {
    this.bcService.OnPageLoad([Pages.Home]);
  }

  OnBodyClick() {
    this.eventHub.emit(GlobalEvent.BodyClick);
  }
}
