import { Component, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalEvent } from 'src/app/model/event/global-events';
import { AuthService } from 'src/app/services/auth-service';
import { EventHub } from 'src/app/services/event-hub';
import { UserService } from 'src/app/services/user-service';
import { ComponentBase } from '../../component-base';

@Component({
  selector: 'top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent extends ComponentBase implements OnInit {
  languageSelVisible = false;

  get currentUser$() {
    return this.userService.currentUser$;
  }

  get language() {
    return this.langService.currentLang;
  }

  constructor(
    eventHub: EventHub,
    private langService: TranslateService,
    private authService: AuthService,
    private userService: UserService) {
    super();

    this.subscribe(eventHub.on(GlobalEvent.BodyClick), () => {
      this.languageSelVisible = false;
    });
   }

  ngOnInit(): void {
  }

  onLanguageSelectorClick(e: Event): void {
    this.languageSelVisible = true;

    e.stopPropagation();
    e.preventDefault();
  }

  onSetLanguage(lang: string): void {
    this.langService.use(lang);
  }

  onLogout(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    this.authService.logOut().subscribe();
  }
};
