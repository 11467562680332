import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noComma'
})
export class NoCommaPipe implements PipeTransform {

  transform(value: string | null, ...args: unknown[]): unknown {
    if (value) {
      return value.replace(',', '');
    }
    return value;
  }

}
