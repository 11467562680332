import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'rw-modal',
  templateUrl: './rw-modal.component.html',
  styleUrls: ['./rw-modal.component.scss']
})
export class RwModalComponent implements OnInit {
  @Input()
  noOverlay: boolean = false;

  @Input()
  title: string = '';

  @Input()
  isVisible: boolean = true;

  @Output()
  isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  modalClosed: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onCloseClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
    this.modalClosed.emit();
  }
};
