import { Injectable } from '@angular/core';
import { AsEnumerable, Enumerable } from 'linq-es2015';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Api } from '../api/api';
import { GlobalEvent } from '../model/event/global-events';
import { UserModel } from '../model/users/user';
import { UserChangeModel } from '../model/users/user-change-model';
import { EventHub } from './event-hub';
import { CreateUserResult } from './op-results/create-user-result';
import { UpdateUserResult } from './op-results/update-user-result';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  #currentUser: UserModel | null = null;
  #users: Enumerable<UserModel> = AsEnumerable([]);

  #currentUser$: ReplaySubject<UserModel | null> = new ReplaySubject<UserModel | null>(1);
  #users$: ReplaySubject<Enumerable<UserModel>> = new ReplaySubject<Enumerable<UserModel>>(1);

  get currentUser$(): Observable<UserModel | null> {
    return this.#currentUser$;
  }

  get users$(): Observable<Enumerable<UserModel>> {
    return this.#users$;
  }

  constructor(private api: Api, private eventHub: EventHub) {
    this.#currentUser$.next(this.#currentUser);
    this.#users$.next(this.#users);

    this.eventHub.on(GlobalEvent.Logout).subscribe({
      next: this.resetCurrentUser.bind(this)
    });
  }

  fetchUser(email: string): Observable<boolean> {
    return this.api.users.getUser(email).pipe(
      map(u => {
        if (u) {
          this.#currentUser = u;
          this.#currentUser$.next(this.#currentUser);
          return true;
        } else {
          return false;
        }
      })
    );
  }

  fetchAllUsers(): Observable<boolean> {
    return this.api.users.getAllUsers().pipe(
      map(u => {
        this.#users = AsEnumerable(u);
        this.#users$.next(AsEnumerable(this.#users));
        return true;
      })
    );
  }

  createNewUser(model: UserChangeModel): Observable<CreateUserResult> {
    return this.api.users.create(model).pipe(
      map(u => {
        if (u != null) {
          this.#users = this.#users.Concat([u]);
          this.#users$.next(AsEnumerable(this.#users));

          return CreateUserResult.Success;
        }
        return CreateUserResult.Error;
      }),
      catchError((e) => {
        if (e.status === 409) {
          return of(CreateUserResult.Duplicate);
        }
        if (e.status === 400) {
          if (e.error.type === 'InputDataMalformed') {
            return of(CreateUserResult.DataIssue);
          } else if (e.error.type === 'PasswordStrength') {
            return of(CreateUserResult.PasswordStrength);
          }
        }
        return throwError(e);
      })
    );
  }

  updateUser(model: UserChangeModel): Observable<UpdateUserResult> {
    return this.api.users.update(model).pipe(
      map(u => {
        if (u != null) {
          this.#users = this.#users
            .Where(us => us.email !== u.email)
            .Concat([u]);
          this.#users$.next(AsEnumerable(this.#users));

          return UpdateUserResult.Success;
        }
        return UpdateUserResult.Error;
      }),
      catchError((e) => {
        if (e.status === 404) {
          return of(UpdateUserResult.UserNotFound);
        }
        if (e.status === 400
          && e.error.type === 'InputDataMalformed') {
          return of(UpdateUserResult.DataIssue);
        }
        return throwError(e);
      })
    );
  }

  resetCurrentUser() {
    this.#currentUser$.next(null);
  }
}
