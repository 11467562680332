export class UserModel {
  userId: string = '';
  email: string = '';
  isAdmin: boolean = false;
  carriers: Array<string> = [];
  isActive: boolean = true;
  isNew: boolean = false;
  createdBy: string = '';
  created: Date = new Date();
  updatedBy: string = '';
  updated: Date = new Date();
};
