export class ShipmentChangeApiModel {
  driverNameChnage: boolean = false;
  driverName: string = '';
  licensePlateChanged: boolean = false;
  licensePlate: string = '';
  driverMobileChanged: boolean = false;
  driverMobile: string = '';
  messageToDriverChanged: boolean = false;
  messageToDriver: string = '';
  plannedCheckinStartChanged: boolean = false;
  plannedCheckInStart: Date | null = null;
  dataProvideDuringCheckIn: boolean = false;
  dataProvideDuringCheckInChanged: boolean = false;
  Deliveries: {
    deliveryNumber: string;
    etaChanged: boolean;
    eta: Date | null;
    completionDateChanged: boolean;
    completionDate: Date | null;
  }[] | null = null;
};
