import { Component, OnDestroy, OnInit } from '@angular/core';
import { Enumerable, asEnumerable } from 'linq-es2015';
import { BreadcrumbModel } from 'src/app/model/breadcrumb-model';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs-service';
import { ComponentBase } from '../../component-base';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent extends ComponentBase implements OnInit, OnDestroy {
  #crumbs: Array<BreadcrumbModel> = [];

  get crumbs(): Enumerable<BreadcrumbModel> {
    return asEnumerable(this.#crumbs);
  }

  constructor(private bcService: BreadcrumbsService) {
    super();

    this.subscribe(this.bcService.items, this.onBreadCrumbUpdate);
  }

  ngOnInit(): void {
  }

  private onBreadCrumbUpdate(newCrumbs: Array<BreadcrumbModel>): void {
    newCrumbs.forEach(crumb => {
      this.#crumbs.push(crumb);
    });
  }
};
