import { AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { format, parse } from 'date-fns';
import { Enumerable } from 'linq-es2015';
import { Observable } from 'rxjs/internal/Observable';
import { pairwise, startWith } from 'rxjs/operators';
import { DeliveryModel } from 'src/app/model/shipment/delivery-model';
import { ShipmentChangeModel } from 'src/app/model/shipment/shipment-change-model';
import { ShipmentModel } from 'src/app/model/shipment/shipment-model';
import { SixfoldLicensePlateModel } from 'src/app/model/sixfold/sixfold-license-plate-model';
import { SixfoldService } from 'src/app/services/sixfold-service';
import { ShipmentStatus } from 'src/app/types/shipment-status';

@Component({
  selector: 'shipment-edit',
  templateUrl: './shipment-edit.component.html',
  styleUrls: ['./shipment-edit.component.scss']
})
export class ShipmentEditComponent implements OnInit, OnChanges {
  @Input()
  shipment: ShipmentModel  = new ShipmentModel();

  @Input()
  isUpdating: boolean = false;

  @Output()
  cancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  save: EventEmitter<ShipmentChangeModel> = new EventEmitter<ShipmentChangeModel>();

  zeroTimeString = '00:00';
  #dateFormat = 'dd.MM.yyyy';
  #timePattern = /^[0-9]{2}:[0-9]{2}$/;

  get licensePlates(): Observable<Enumerable<SixfoldLicensePlateModel>> {
    return this.sixfoldService.licensePlates$;
  }

  timeOptions: Array<string> = [];
  isAnyDeliverySoon: boolean = false;
  //FormControls
  driverNameControl: FormControl = new FormControl();
  licensePlateControl: FormControl = new FormControl();
  driverMobileControl: FormControl = new FormControl();
  messageToDriverControl: FormControl = new FormControl();
  plannedCheckInStartControl: FormControl = new FormControl();
  dataProvidedDuringCheckInControl: FormControl = new FormControl();
  checkInTimeControl: FormControl = new FormControl(this.zeroTimeString, Validators.pattern(this.#timePattern));
  deliveryDateControls: {[Key: string]: FormControl } = {};
  deliveryTimeControls: {[Key: string]: FormControl } = {};
  deliveryCompletionDateControls: {[Key: string]: FormControl } = {};
  deliveryCompletionTimeControls: {[Key: string]: FormControl } = {};
  completionPopupVisibility: {[Key: string]: boolean } = {};
  completionDates: {[Key: string]: Date | null } = {};
  deliverySoonDictionary: {[Key: string]: boolean } = {};

  warnings: {
    outsideRequestedDate: {[Key: string]: boolean | null };
    anyOutsideRequested: () => boolean;
    anyWarning: () => boolean;
  } = {
    outsideRequestedDate: {},
    anyOutsideRequested() { return Object.keys(this.outsideRequestedDate).length > 0; },
    anyWarning() { return this.anyOutsideRequested(); }
  };

  formGroup: FormGroup = new FormGroup({
    dummy: new FormControl(Validators.required)
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ShipmentStatus = ShipmentStatus;

  get isEditable() {
    return this.shipment.status === ShipmentStatus.Closed || this.shipment.checkInDate != null;
  }

  get isCheckInTimeEditable() {
    const now = new Date(Date.now());

    if (this.shipment.plannedCheckInStart) {
      const nowInt = now.getFullYear() * 10000 + now.getMonth() * 100 + now.getDate();
      const checkInInt = this.shipment.plannedCheckInStart.getFullYear() * 10000
        + this.shipment.plannedCheckInStart.getMonth() * 100
        + this.shipment.plannedCheckInStart.getDate();

      return this.shipment.plannedCheckInStart
        ? checkInInt > nowInt
        : false;
    }
    return true;
  }

  get deliveryCount() {
    return this.shipment?.deliveries?.length ?? 0;
  }

  constructor(private sixfoldService: SixfoldService) {
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j = j + 15) {
        this.timeOptions.push(`${i.toString().padStart(2, '0')}:${j.toString().padStart(2, '0')}`);
      }
    }
  }

  ngOnInit(): void {
    this.checkInTimeControl.valueChanges
      .pipe(startWith(''), pairwise())
      .subscribe({
        next: ([prev, current]) => this.patchTimeValue(prev, current, this.checkInTimeControl)
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shipment) {
      this.setupControls();
    }
  }

  clearControl(e: Event, control: FormControl, value: any = '') {
    e.preventDefault();
    e.stopPropagation();

    control.setValue(value);
  }

  onCancel() {
    this.cancel.emit();
  }

  onSave() {
    const changeModel = new ShipmentChangeModel();
    changeModel.shipment = this.shipment;
    changeModel.checkInTime = this.checkInTimeControl.value;
    changeModel.driverMobile = this.driverMobileControl.value;
    changeModel.driverName = this.driverNameControl.value;
    changeModel.licensePlate = this.licensePlateControl.value;
    changeModel.messageToDriver = this.messageToDriverControl.value;
    const checkInDate = this.getDateTime(this.checkInTimeControl.value, this.plannedCheckInStartControl.value);
    changeModel.plannedCheckInStart = checkInDate;
    changeModel.dataProvideDuringCheckIn = this.dataProvidedDuringCheckInControl.value;

    changeModel.deliveryDates = {};
    this.shipment.deliveries?.forEach(delivery => {
      const date = this.getDateTime(
        this.deliveryTimeControls[delivery.deliveryNumber].value,
        this.deliveryDateControls[delivery.deliveryNumber].value);

      changeModel.deliveryDates[delivery.deliveryNumber] = date;
      changeModel.completionDates[delivery.deliveryNumber] = this.completionDates[delivery.deliveryNumber];
    });

    this.save.emit(changeModel);
  }

  onCompletionSave(delivery: DeliveryModel) {
    this.completionPopupVisibility[delivery.deliveryNumber] = false;

    const completionDateString = this.deliveryCompletionDateControls[delivery.deliveryNumber].value;
    if (completionDateString) {
      const completionDate = this.getDateTime(this.deliveryCompletionTimeControls[delivery.deliveryNumber].value, completionDateString);
      this.completionDates[delivery.deliveryNumber] = completionDate;
    }
  }

  onSetDeliveryDate(e: Event, delivery: DeliveryModel) {
    e.stopPropagation();
    e.preventDefault();

    const newEta = delivery.requestedDeliveryDateFrom
      ? this.getFuterDataOrNow(delivery.requestedDeliveryDateFrom)
      : this.getFuterDataOrNow(this.shipment.plannedCheckInStart ?? new Date());

    this.deliveryDateControls[delivery.deliveryNumber].setValue(format(newEta, this.#dateFormat));

    this.deliveryTimeControls[delivery.deliveryNumber].setValue(
      this.extractTimeString(newEta));
  }

  validateDeliveryDates(control: AbstractControl): ValidationErrors | null {
    let anyError = false;

    this.shipment.deliveries?.forEach(d => {
      if (d.deliveryNumber in this.deliveryDateControls) {
        const value = this.deliveryDateControls[d.deliveryNumber].value;
        if (value) {
          const etaDate = this.getDateTime(this.deliveryTimeControls[d.deliveryNumber].value, value);
          const checkIn = this.getDateTime(this.checkInTimeControl.value, this.plannedCheckInStartControl.value);

          if (checkIn && etaDate && etaDate < checkIn) {
            anyError = true;

            const dateErrors = this.deliveryDateControls[d.deliveryNumber].errors || {};
            dateErrors.deliveryInPast = true;
            this.deliveryDateControls[d.deliveryNumber].setErrors(dateErrors);

            const timeErrors = this.deliveryTimeControls[d.deliveryNumber].errors || {};
            timeErrors.deliveryInPast = true;
            this.deliveryTimeControls[d.deliveryNumber].setErrors(timeErrors);
          } else {
            if (this.deliveryDateControls[d.deliveryNumber].errors?.deliveryInPast) {
              const errors = this.deliveryDateControls[d.deliveryNumber].errors;
                if (errors) {
                delete errors?.deliveryInPast;
                if (Object.keys(errors).length > 0) {
                  this.deliveryDateControls[d.deliveryNumber].setErrors(errors);
                } else {
                  this.deliveryDateControls[d.deliveryNumber].setErrors(null);
                }
              }
            }

            if (this.deliveryTimeControls[d.deliveryNumber].errors?.deliveryInPast) {
              const errors = this.deliveryTimeControls[d.deliveryNumber].errors;
              if (errors) {
                delete errors?.deliveryInPast;
                 if (Object.keys(errors).length > 0) {
                  this.deliveryTimeControls[d.deliveryNumber].setErrors(errors);
                } else {
                  this.deliveryTimeControls[d.deliveryNumber].setErrors(null);
                }
              }
            }
          }

          if ((d.requestedDeliveryDateFrom != null && etaDate && etaDate < d.requestedDeliveryDateFrom)
            || (d.requestedDeliveryDateTo != null && etaDate && etaDate > d.requestedDeliveryDateTo)) {
            this.warnings.outsideRequestedDate[d.deliveryNumber] = true;
          } else if (this.warnings.outsideRequestedDate[d.deliveryNumber]) {
            delete this.warnings.outsideRequestedDate[d.deliveryNumber];
          }
        }
      }
    });

    return anyError ? { deliveryInPast: 'Delivery date is invalid.' } : null;
  }

  hasAnyTimeFormatErrors(): boolean {
    for(const control of Object.keys(this.formGroup.controls)) {

      if (this.formGroup.controls[control].errors?.pattern
        && this.formGroup.controls[control].errors?.pattern.requiredPattern) {
          console.log(this.formGroup.controls[control].errors?.pattern.requiredPattern === this.#timePattern.toString());
          return true;
      }
    }

    return false;
  }

  private getFuterDataOrNow(date: Date): Date {
    if (date.getTime() < Date.now()) {
      return new Date(Date.now());
    } else {
      return date;
    }
  }

  private setupControls() {
    const dateNow = new Date(); // get the current time
    const currentTzOffset = -1 * dateNow.getTimezoneOffset();

    this.deliveryDateControls = {};
    this.deliveryTimeControls = {};
    this.deliveryCompletionDateControls = {};
    this.deliveryCompletionTimeControls = {};
    this.completionPopupVisibility = {};
    this.completionDates = {};

    if (this.shipment.plannedCheckInStart) {
      this.calculateIfDeliveryIsSoon(this.shipment.plannedCheckInStart);
    }
    this.driverNameControl.setValue(this.shipment.driverName);
    this.licensePlateControl.setValue(this.shipment.licensePlate);
    this.driverMobileControl.setValue(this.shipment.driverMobile);
    this.messageToDriverControl.setValue(this.shipment.messageToDriver);
    this.dataProvidedDuringCheckInControl.setValue(this.shipment.dataProvideDuringCheckIn);
    if (!this.isEditable) {
      this.dataProvidedDuringCheckInControl.disable();
    } else {
      this.dataProvidedDuringCheckInControl.enable();
    }

    if (this.shipment.plannedCheckInStart) {
      this.plannedCheckInStartControl.setValue(format(this.shipment.plannedCheckInStart, this.#dateFormat));
      this.checkInTimeControl.setValue(this.extractTimeString(this.shipment.plannedCheckInStart));
    } else {
      this.checkInTimeControl.setValue(this.zeroTimeString);
    }

    let formGroupCollection = {} as any;
    formGroupCollection = {
      driverName: this.driverNameControl,
      licensePlate: this.licensePlateControl,
      driverMobile: this.driverMobileControl,
      messageToDriver: this.messageToDriverControl,
      plannedCheckInStart: this.plannedCheckInStartControl,
      checkInTime: this.checkInTimeControl,
      dataProvidedDuringCheckIn: this.dataProvidedDuringCheckInControl
    };

    if (this.shipment.deliveries && this.shipment.deliveries.length) {
      this.shipment.deliveries.forEach(d => {
        this.deliverySoonDictionary[d.deliveryNumber] = this.calculateIfDeliveryIsSoon(d.eta ?? d.requestedDeliveryDateFrom);

        this.deliveryDateControls[d.deliveryNumber] = new FormControl(null);
        this.deliveryTimeControls[d.deliveryNumber] = new FormControl(this.zeroTimeString, Validators.pattern(this.#timePattern));
        this.deliveryTimeControls[d.deliveryNumber].valueChanges
          .pipe(startWith(''), pairwise())
          .subscribe({
            next: ([prev, current]) => this.patchTimeValue(prev, current, this.deliveryTimeControls[d.deliveryNumber])
          });

        this.deliveryCompletionDateControls[d.deliveryNumber] = new FormControl();
        this.deliveryCompletionTimeControls[d.deliveryNumber] = new FormControl();
        if (d.completionDate) {
          this.deliveryCompletionDateControls[d.deliveryNumber].setValue(format(d.completionDate, this.#dateFormat));
          this.deliveryCompletionTimeControls[d.deliveryNumber].setValue(this.extractTimeString(d.completionDate));
        } else if (d.eta) {
          this.deliveryCompletionDateControls[d.deliveryNumber].setValue(format(d.eta, this.#dateFormat));
          this.deliveryCompletionTimeControls[d.deliveryNumber].setValue(this.extractTimeString(d.eta));
        } else {
          this.deliveryCompletionDateControls[d.deliveryNumber].setValue(format(Date.now(), this.#dateFormat));
          this.deliveryCompletionTimeControls[d.deliveryNumber].setValue(this.zeroTimeString);
        }
        this.completionPopupVisibility[d.deliveryNumber] = false;
        this.completionDates[d.deliveryNumber] = d.completionDate;

        formGroupCollection['deliveryDate' + d.deliveryNumber] = this.deliveryDateControls[d.deliveryNumber];
        formGroupCollection['deliveryTime' + d.deliveryNumber] = this.deliveryTimeControls[d.deliveryNumber];

        if (d.eta) {
          this.deliveryDateControls[d.deliveryNumber].setValue(format(d.eta, this.#dateFormat));
          this.deliveryTimeControls[d.deliveryNumber].setValue(this.extractTimeString(d.eta), {emitEvent:false});
        } else {
          this.deliveryTimeControls[d.deliveryNumber].setValue(this.zeroTimeString, {emitEvent:false});
        }
      });

      this.isAnyDeliverySoon = Object.keys(this.deliverySoonDictionary)
        .reduce((p, c) => p || this.deliverySoonDictionary[c] , false as boolean);
    }

    this.formGroup = new FormGroup(formGroupCollection, { validators: [this.validateDeliveryDates.bind(this)] });
  }

  private patchTimeValue(prev: any, current: any, control: FormControl) {
    if (current && current.length === 2 && prev && prev.length === 1) {
      control.setValue(`${current}:`);
    } else if (current && current.length === 2 && prev && prev.length === 3) {
      control.setValue(`${current.substr(0, 1)}`);
    }
  }

  private getDateTime(time: string, dateString: string): Date | null {
    if (!dateString) {
      return null;
    }

    if (!time || !time.length || time.length !== 5) {
      time = this.zeroTimeString;
    }

    const date = parse(dateString, this.#dateFormat, new Date());
    const hours = Number.parseInt(time.substring(0, 2), 10);
    const min = Number.parseInt(time.substring(3, time.length), 10);

    if (Number.isInteger(hours) && Number.isInteger(min)) {
      date.setHours(hours);
      date.setMinutes(min);
    }

    return date;
  }

  private extractTimeString(date: Date) {
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  }

  private calculateIfDeliveryIsSoon(deliveryEta: Date | null) {
    if (!deliveryEta) {
      return false;
    }

    const now = new Date(Date.now());

    const diff = deliveryEta.getTime() - now.getTime();

    if (diff > 0) {
      return diff < (35 * 60 * 60 * 1000); //Block since 13:00PM of previous day
    } else {
      return false;
    }
  }
}
