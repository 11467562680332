import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserModel } from 'src/app/model/users/user';
import { UserChangeModel } from 'src/app/model/users/user-change-model';
import { EventHub } from 'src/app/services/event-hub';
import { LoggingService } from 'src/app/services/logging-service';
import { NotificationService } from 'src/app/services/notification-service';
import { ControllerBase } from '../controller-base';
import { SignupResponse } from '../reponse/signup-response';
import { UpdateUserResponse } from '../reponse/update-user-response';
import { UserResponse } from '../reponse/user-response';
import { UsersResponse } from '../reponse/users-response';

@Injectable({
  providedIn: 'root'
})
export class UsersController extends ControllerBase {
  constructor(http: HttpClient, eventHub: EventHub, notificationService: NotificationService, log: LoggingService) {
    super('users', http, eventHub, notificationService, log);
  }

  getUser(email: string): Observable<UserModel | null> {
    return this.
      get<UserResponse>(email).
      pipe(
        map(r => {
          if (r.user) {
            const user = new UserModel();
            user.email = r.user.email ?? '';
            user.carriers = r.user.carriers;
            user.isAdmin = r.user.isAdmin;
            user.isActive = r.user.isActive;
            user.userId = r.user.userId;
            user.created = r.user.created ? new Date(r.user.created + '+00:00') : new Date();
            user.updated = r.user.updated ? new Date(r.user.updated + '+00:00') : new Date();
            user.createdBy = r.user.createdBy ?? '';
            user.updatedBy = r.user.updatedBy ?? '';
            return user;
          }
          return null;
        }),
        catchError(e => this.handleError(e))
      );
  }

  getAllUsers(): Observable<UserModel[]> {
    return this.
      get<UsersResponse>('').
      pipe(
        map(r => {
          if (r.users && r.users.length) {
            return r.users.map(u => {
              const user = new UserModel();
              user.email = u.email ?? '';
              user.carriers = u.carriers;
              user.isAdmin = u.isAdmin;
              user.isActive = u.isActive;
              user.userId = u.userId;
              user.created = u.created ? new Date(u.created + '+00:00') : new Date();
              user.updated = u.updated ? new Date(u.updated + '+00:00') : new Date();
              user.createdBy = u.createdBy ?? '';
              user.updatedBy = u.updatedBy ?? '';
              return user;
            });
          }
          return [];
        }),
        catchError(e => this.handleError(e))
      );
  }

  create(newUser: UserChangeModel): Observable<UserModel | null> {
    return this.
      post<SignupResponse>('signup', {
        email: newUser.email,
        carriers: newUser.carriers,
        isAdmin: newUser.isAdmin,
        isActive: newUser.isActive,
        password: newUser.password
      }).
      pipe(
        map(r => {
          if (r.isSuccess) {
            const user = new UserModel();
            user.email = r.user?.email ?? '';
            user.carriers = r.user?.carriers ?? [];
            user.isAdmin = r.user?.isAdmin ?? false;
            user.isActive = r.user?.isActive ?? false;
            user.userId = r.user?.userId ?? '';
            user.created = r.user?.created ? new Date(r.user.created + '+00:00') : new Date();
            user.updated = r.user?.updated ? new Date(r.user.updated + '+00:00') : new Date();
            user.createdBy = r.user?.createdBy ?? '';
            user.updatedBy = r.user?.updatedBy ?? '';
            return user;
          }
          return null;
        }),
        catchError(e => this.handleError(e))
      );
  }

  update(updatedUser: UserChangeModel): Observable<UserModel | null> {
    return this.
      put<UpdateUserResponse>('', {
        email: updatedUser.email,
        newCarriers: updatedUser.carriers,
        newIsAdmin: updatedUser.isAdmin,
        newIsActive: updatedUser.isActive
      }).
      pipe(
        map(r => {
          if (r.isSuccess) {
            const user = new UserModel();
            user.email = r.user?.email ?? '';
            user.carriers = r.user?.carriers ?? [];
            user.isAdmin = r.user?.isAdmin ?? false;
            user.isActive = r.user?.isActive ?? false;
            user.userId = r.user?.userId ?? '';
            user.created = r.user?.created ? new Date(r.user.created + '+00:00') : new Date();
            user.updated = r.user?.updated ? new Date(r.user.updated + '+00:00') : new Date();
            user.createdBy = r.user?.createdBy ?? '';
            user.updatedBy = r.user?.updatedBy ?? '';
            return user;
          }
          return null;
        }),
        catchError(e => this.handleError(e))
      );
  }
};
