export class ExtraStopModel {
    rootSequence: number = 0;
    sequenceNumber: number | null = null;
    city: string = '';
    postalCode: string = '';
    countryCode: string = '';
    loadingUnloadingMinutes: string = '';
    type: string = '';
    name: string = '';
    deliveryNumbers: string[] | null = [];
}
