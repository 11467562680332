import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { BreadcrumbModel } from '../model/breadcrumb-model';
import { Pages } from '../types/pages';
import { PageService } from './page-service';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  #items: ReplaySubject<Array<BreadcrumbModel>>;

  get items(): ReplaySubject<Array<BreadcrumbModel>> {
    return this.#items;
  }

  constructor(private pageService: PageService) {
    this.#items = new ReplaySubject<Array<BreadcrumbModel>>(1);
  }

  OnPageLoad(crumbs: Array<Pages>) {
    const data = new Array<BreadcrumbModel>();
    crumbs.forEach(pageType => {
      const name = this.pageService.pageNameKeyByType[pageType];
      const url = this.pageService.pageUrlByType[pageType];
      const model = new BreadcrumbModel(name, url);
      data.push(model);
    });

    this.#items.next(data);
  }
};
