import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  static characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  static getRandomString(length: number): string {
    let result = '';
    for (let i = 0; i < length; i++ ) {
      result += UtilService.characters.charAt(Math.floor(Math.random() * UtilService.characters.length));
   }

   return result;
  }
};
